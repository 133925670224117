import React from "react";
import classnames from "classnames";

import styles from "./index.module.scss";

function YearButton({ title, className, isActive, onClick }) {
  return (
    <button
      onClick={onClick}
      className={classnames(styles.container, className, {
        [styles.isActive]: isActive,
      })}
    >
      {title}
    </button>
  );
}

export default YearButton;
