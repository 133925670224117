import React from "react";
import { useFormik } from "formik";
import FormField from "components/FormField";
import validation from "./validation";
import { getBackendUrl } from "lib/config";
import { useTranslations } from "lib/translations";

import styles from "./index.module.scss";

function Feedback() {
  const { translate } = useTranslations();

  const formik = useFormik({
    initialValues: {
      name: "",
      company: "",
      phone: "",
      email: "",
      message: "",
    },
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values, formikBag) => {
      try {
        const response = await fetch(`${getBackendUrl()}/api/feedbacks`, {
          method: "POST",
          body: JSON.stringify(values),
        });
        formikBag.resetForm();
        return response;
      } catch (error) {
        return;
      }
    },
    validate: validation,
  });

  function handleSubmit(event, values) {
    event.preventDefault();
    formik.handleSubmit(values);
  }

  return (
    <section className={styles.container} id="feedback">
      <div className={styles.formWrap}>
        <h2 className={styles.title}>{translate("feedback.header")}</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <FormField
              className={styles.formField}
              inputProps={{ className: styles.formInput }}
              name="name"
              type="name"
              placeholder={translate("feedback.name")}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={translate(formik.errors.name)}
              touched={formik.touched.name}
              onBlur={formik.handleBlur}
            />
            <FormField
              name="company"
              type="company"
              placeholder={translate("feedback.company")}
              onChange={formik.handleChange}
              value={formik.values.company}
              inputProps={{ className: styles.formInput }}
              error={translate(formik.errors.company)}
              touched={formik.touched.company}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className={styles.formGroup}>
            <FormField
              name="email"
              inputProps={{ className: styles.formInput }}
              name="email"
              placeholder={translate("feedback.email")}
              onChange={formik.handleChange}
              value={formik.values.email}
              error={translate(formik.errors.email)}
              touched={formik.touched.email}
              onBlur={formik.handleBlur}
            />
            <FormField
              name="phone"
              placeholder={translate("feedback.phone")}
              onChange={formik.handleChange}
              value={formik.values.phone}
              inputProps={{ className: styles.formInput }}
              error={translate(formik.errors.phone)}
              touched={formik.touched.phone}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className={styles.formMessage}>
            <FormField
              name="message"
              placeholder={translate("feedback.message")}
              onChange={formik.handleChange}
              value={formik.values.message}
              inputProps={{
                className: styles.formMessageInput,
              }}
              error={translate(formik.errors.message)}
              touched={formik.touched.message}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className={styles.btnBlock}>
            <div className={styles.pixelTop_first}></div>
            <div className={styles.pixelTop_second}></div>
            <div className={styles.pixelTop_third}></div>
            <button
              className={styles.formButton}
              disabled={formik.isSubmitting || !formik.isValid}
              type="submit">
              {translate("feedback.send")}
            </button>
            <div className={styles.pixelBottom_first}></div>
            <div className={styles.pixelBottom_second}></div>
            <div className={styles.pixelBottom_third}></div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Feedback;
