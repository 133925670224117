import React from "react";

import styles from "./index.module.scss";

function Loader() {
  return (
    <div className={styles.loader}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Loader;
