import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { buildAssetsPath } from "lib/config";
import { useTranslations } from "lib/translations";

import styles from "./index.module.scss";

function PersonJury({
  img,
  size,
  fullName,
  hasNomination,
  nominationTitle,
  nominationIcon,
  company,
  awardPosition,
  onClick,
}) {
  const { translate } = useTranslations();
  const [shoudRotate, setShouldRotate] = useState(false);
  const infoBlockRef = useRef();

  useEffect(() => {
    function handleCheckIsNeedRotate() {
      const elementEdgePosition =
        infoBlockRef.current.getBoundingClientRect().width +
        infoBlockRef.current.getBoundingClientRect().right;
      const innerWidth = window.innerWidth;

      if (elementEdgePosition > innerWidth) {
        setShouldRotate(true);
      } else {
        setShouldRotate(false);
      }
    }
    if (infoBlockRef.current) {
      handleCheckIsNeedRotate();
      window.addEventListener("resize", handleCheckIsNeedRotate);
    }

    return () => {
      window.removeEventListener("resize", handleCheckIsNeedRotate);
    };
  }, []);

  return (
    <div
      ref={infoBlockRef}
      className={classnames(styles.container, {
        [styles.lg]: size === "lg",
        [styles.md]: size === "md",
      })}
      onClick={onClick}
    >
      <img src={img} alt="person-photo" loading="lazy" className={styles.img} />
      {hasNomination && (
        <img src={buildAssetsPath(nominationIcon)} className={styles.icon} />
      )}

      <div
        className={classnames(styles.info, {
          [styles.infoRotate]: shoudRotate,
        })}
      >
        <p className={styles.name}>{fullName}</p>
        <p className={styles.label}>
          {hasNomination ? awardPosition : translate("jury")}
        </p>
        <p className={styles.nomination}>{hasNomination && nominationTitle}</p>
        <p className={styles.workplace}>{company}</p>
      </div>
    </div>
  );
}

export default PersonJury;
