import React from "react";
import Icon from "components/Icon";
import { useTranslations } from "lib/translations";

import styles from "./index.module.scss";

function Rules({ rulesDocument }) {
  const { translate } = useTranslations();
  return (
    <section className={styles.container} id="rules">
      <div className={styles.containerWrap}>
        <h2>{translate("rules.header")}</h2>

        <div className={styles.wrapper}>
          <div className={styles.content}>
            <h6 className={styles.contentNumber}>I</h6>
            <p className={styles.contentText}>
              {translate("rules.p1")}
              {/*{translate("rules.p2")}*/}
              {/*<p className={styles.bold}>{translate("rules.p3")}</p>*/}
            </p>
          </div>
          <div className={styles.content}>
            <h6 className={styles.contentNumber}>II</h6>
            <p className={styles.contentText}>
              {translate("rules.p4")}{" "}
              {/*<p className={styles.bold}>{translate("rules.p5")}</p>*/}
              {/*{translate("rules.p6")}*/}
              <p className={styles.square}> {translate("rules.p7")}</p>
              <p className={styles.square}> {translate("rules.p8")}</p>
              <p className={styles.square}> {translate("rules.p9")}</p>
            </p>
          </div>
          <div className={styles.content}>
            <h6 className={styles.contentNumber}>III</h6>
            <p className={styles.contentText}>
              {translate("rules.p10")}
              {/*<p className={styles.bold}>{translate("rules.p11")}</p>*/}
            </p>
          </div>
          <div className={styles.rightContent}>
            <p className={styles.rightContentText}>{translate("rules.p12")}</p>
            <p className={styles.rightContentText}>{translate("rules.p13")}</p>
            <a href={rulesDocument} target="_blank">
              <button className={styles.button}>
                {translate("rules.p14")}
                <Icon iconName="arrow" className={styles.icon} />
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Rules;
