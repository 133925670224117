import React, { useMemo } from "react";
import classNames from "classnames";
import * as Config from "lib/config";

import styles from "./index.module.scss";

import VideoPreview from "./VideoPreview";

/**
 *
 * Gallery constists of segments
 * Segments has 5 types
 *
 * Segement 1
 *  --- --- ---
 *  |P| |P| |P|
 *  --- --- ---
 *  --- --- ---
 *  |P| |P| |P|
 *  --- --- ---
 *  -----------
 *  |  VIDEO  |
 *  -----------
 *
 * Segment 2
 *  --- --- ---
 *  |P| |P| |P|
 *  --- --- ---
 *  -----------
 *  |  VIDEO  |
 *  -----------
 *  --- --- ---
 *  |P| |P| |P|
 *  --- --- ---
 *
 * Segment 3
 *  -----------
 *  |  VIDEO  |
 *  -----------
 *  --- --- ---
 *  |P| |P| |P|
 *  --- --- ---
 *  --- --- ---
 *  |P| |P| |P|
 *  --- --- ---
 *
 * Segment 4
 *  --- --- ---
 *  |P| |P| |P|
 *  --- --- ---
 *  --- --- ---
 *  |P| |P| |P|
 *  --- --- ---
 *  --- --- ---
 *  |P| |P| |P|
 *  --- --- ---
 *
 * Segment 5
 *  -----------
 *  |  VIDEO  |
 *  -----------
 *  -----------
 *  |  VIDEO  |
 *  -----------
 *
 *
 * This file describe logic how to render gallery with linier video dsistribution among all gallery with this 5 segment types
 */

function renderPhotoBlock([photo1, photo2], onClick) {
  return (
    <React.Fragment>
      {photo1 ? (
        <div className={styles.segmentImage} onClick={() => onClick(photo1)}>
          <img
            src={Config.buildAssetsPath(
              photo1.formats?.small?.url ?? photo1.formats?.thumbnail?.url
            )}
            loading="lazy"
          />
        </div>
      ) : null}

      {photo2 ? (
        <div className={styles.segmentImage} onClick={() => onClick(photo2)}>
          <img
            src={Config.buildAssetsPath(
              photo2.formats?.small?.url ?? photo2.formats?.thumbnail?.url
            )}
            loading="lazy"
          />
        </div>
      ) : null}
    </React.Fragment>
  );
}

function renderVideoBlock(video, onClick) {
  return video ? (
    <div className={styles.segmentVideo} onClick={() => onClick(video)}>
      <VideoPreview src={Config.buildAssetsPath(video.url)} />
    </div>
  ) : null;
}

function renderSegment1({ photos: [p1, p2, p3, p4], videos: [v1], onClick }) {
  return (
    <div key={p1?.id} className={classNames(styles.segment)}>
      {renderPhotoBlock([p1, p2], onClick)}
      {renderPhotoBlock([p3, p4], onClick)}
      {renderVideoBlock(v1, onClick)}
    </div>
  );
}

function renderSegment2({ photos: [p1, p2, p3, p4], videos: [v1], onClick }) {
  return (
    <div key={p1?.id} className={classNames(styles.segment)}>
      {renderPhotoBlock([p1, p2], onClick)}
      {renderVideoBlock(v1, onClick)}
      {renderPhotoBlock([p3, p4], onClick)}
    </div>
  );
}

function renderSegment3({ photos: [p1, p2, p3, p4], videos: [v1], onClick }) {
  return (
    <div key={p1?.id} className={classNames(styles.segment)}>
      {renderVideoBlock(v1, onClick)}
      {renderPhotoBlock([p1, p2], onClick)}
      {renderPhotoBlock([p3, p4], onClick)}
    </div>
  );
}

function renderSegment4({ photos: [p1, p2, p3, p4, p5, p6, p7, p8], onClick }) {
  return (
    <div key={p1?.id} className={classNames(styles.segment)}>
      {renderPhotoBlock([p1, p2], onClick)}
      {renderPhotoBlock([p3, p4], onClick)}
      {renderPhotoBlock([p5, p6], onClick)}
      {renderPhotoBlock([p7, p8], onClick)}
    </div>
  );
}

function renderSegment5({ videos: [v1, v2], onClick }) {
  return (
    <div key={v1?.id} className={classNames(styles.segment)}>
      {renderVideoBlock(v1, onClick)}
      {renderVideoBlock(v2, onClick)}
    </div>
  );
}

function oneOf(variants = [], hash = "0") {
  const numberOfVariants = variants.length;
  const lastCharOfhash = hash.substr(-1);
  const number = Number.parseInt(lastCharOfhash, 16);
  return variants[number % numberOfVariants];
}

function renderSegment({ photos = [], videos = [], onClick }) {
  const photosCount = photos.length;
  const videosCount = videos.length;

  const hash = (photos[0] | videos[0])?.id;

  if (photosCount === 4 && videosCount === 1) {
    const renderer = oneOf(
      [renderSegment1, renderSegment2, renderSegment3],
      hash
    );

    return renderer({ photos, videos, onClick });
  }

  if (photosCount >= 2 && photosCount < 4 && videosCount === 1) {
    const renderer = oneOf([renderSegment2, renderSegment3], hash);

    return renderer({ photos, videos, onClick });
  }

  if (photosCount === 1 && videosCount === 1) {
    return renderSegment3({ photos, videos, onClick });
  }

  if (photosCount === 0) {
    return renderSegment5({ photos, videos, onClick });
  }

  if (videosCount === 0) {
    return renderSegment4({ photos, videos, onClick });
  }

  return null;
}

function useGalleryRenderers({ photos = [], videos = [], onClick }) {
  /**
   * segment has 4 lines of
   * 2 photos - 1 line
   * 1 video - 2 lines
   */

  return useMemo(() => {
    const totalSegments = Math.ceil(
      (Math.ceil(photos.length / 2) + videos.length * 2) / 4
    );

    const photosCopy = [...photos];
    const videosCopy = [...videos];

    function selectFrom(arr = [], number = 0) {
      const numberOfElementsToSelect = Math.min(arr.length, number);

      const selectedItems = [];

      for (let index = 1; index <= numberOfElementsToSelect; index++) {
        selectedItems.push(arr.shift());
      }

      return selectedItems;
    }

    if (videos.length <= totalSegments) {
      const segmentsPerVideo = Math.trunc(totalSegments / videos.length);

      const renderers = [];

      for (
        let segmentIndex = 0;
        segmentIndex <= totalSegments;
        segmentIndex++
      ) {
        const hasVideoInSegment =
          segmentIndex % segmentsPerVideo === 0 && videosCopy.length > 0;

        if (hasVideoInSegment) {
          const videos = selectFrom(videosCopy, 1);
          const photos = selectFrom(photosCopy, 4);

          renderers.push(() => renderSegment({ videos, photos, onClick }));
        } else {
          const photos = selectFrom(photosCopy, 8);
          renderers.push(() => renderSegment({ videos: [], photos, onClick }));
        }
      }

      return renderers;
    }

    const segmentsPerAditionalVideo = Math.trunc(
      totalSegments / (videos.length - totalSegments)
    );

    const renderers = [];

    for (let segmentIndex = 0; segmentIndex <= totalSegments; segmentIndex++) {
      const hasAditionalVideoInSegment =
        segmentIndex % segmentsPerAditionalVideo === 0 && videosCopy.length > 0;

      if (hasAditionalVideoInSegment) {
        const videos = selectFrom(videosCopy, 2);
        renderers.push(() => renderSegment({ videos, photos: [], onClick }));
      } else {
        const videos = selectFrom(videosCopy, 1);
        const photos = selectFrom(photosCopy, 4);
        renderers.push(() => renderSegment({ videos, photos, onClick }));
      }
    }

    return renderers;
  }, [videos, photos, onClick]);
}

export default useGalleryRenderers;
