import React, { useMemo } from "react";
import AwardJuryBlock from "components/AwardJuryBlock";
import { useTranslations } from "lib/translations";

function Jury({ awards, disableApplications, onOpenJuryApplicationForm }) {
  const { translate } = useTranslations();
  const awardJuries = useMemo(() => {
    return awards.map((award) => {
      const people = award.nominations.reduce(
        (_people, { nomination, juries }) => {
          return _people.concat(
            ...juries.map((jury) => ({
              ...jury,
              nomination,
            }))
          );
        },
        []
      );

      people.push(...award.juries);

      return [award, people];
    });
  }, [awards]);

  return (
    <AwardJuryBlock
      awardPeople={awardJuries}
      disableApplications={disableApplications}
      awardPositionLabel={translate("jury_in_nomination")}
      onOpenJuryApplicationForm={onOpenJuryApplicationForm}
      title={translate("jury")}
      avatarSize="md"
    />
  );
}

export default Jury;
