import React, { useReducer } from "react";
import Select from "components/Select";
import Drawer from "components/Drawer";
import { getBackendUrl } from "lib/config";
import * as Config from "lib/config";
import * as Sentry from "@sentry/node";
import { useTranslations } from "lib/translations";
import ApplyAccepted from "components/ApplyAcceptedDrawer";
import PersonalApplicationForm from "components/PersonalApplicationForm";
import ManagementApplicationForm from "components/ManagementApplicationForm";
import TeamApplicationForm from "components/TeamApplicationForm";
import ErrorApplication from "components/ErrorApplication";

import styles from "./index.module.scss";

const FORM_TYPES = {
  PERSONAL: "personal",
  MANAGEMENT: "management",
  TEAM: "team",
};

const FORM_STATES = {
  ACTIVE: "ACTIVE",
  ACCEPTED: "ACCEPTED",
  FAILED: "FAILED",
};

function formSentSuccessfully() {
  return {
    type: "ACCEPTED",
  };
}

function formSentWithFailures() {
  return {
    type: "FAILED",
  };
}

function resetForm() {
  return { type: "RESET" };
}

function formStateReducer(state, action) {
  switch (action.type) {
    case "RESET":
      return FORM_STATES.ACTIVE;
    case "ACCEPTED":
      return FORM_STATES.ACCEPTED;
    case "FAILED":
      return FORM_STATES.FAILED;
    default:
      return state;
  }
}

function ApplicationForm({
  isOpened,
  onClose,
  nominations,
  currentAward,
  selectedNominationOption,
  partners,
}) {
  const { translate } = useTranslations();

  const [formState, dispatch] = useReducer(
    formStateReducer,
    FORM_STATES.ACTIVE
  );

  const [fullName, setFullName] = React.useState(undefined);

  function formatNominationOptions() {
    return nominations.map((item) => ({
      value: item.nomination.id,
      label: (
        <div className={styles.nominationsSelectOption}>
          <img
            src={Config.buildAssetsPath(item.nomination?.icon?.url)}
            className={styles.nominationsSelectIcon}
          />
          <span>{item.nomination.title}</span>
        </div>
      ),
    }));
  }

  const [selectedNominationValue, setSelectedNominationValue] = React.useState(
    selectedNominationOption ?? formatNominationOptions()[0].value
  );

  const applicationForm = nominations.find(
    (item) => item.nomination.id === selectedNominationValue
  );

  function sendApplication(values) {
    try {
      const formData = new FormData();
      const request = new XMLHttpRequest();
      const { attachments, ...rest } = values;

      let data = {
        application: [
          {
            __component: `applications.${applicationForm.nomination.application_form}`,

            ...rest,
          },
        ],
        award: currentAward?.id,
        nomination: selectedNominationValue,
      };

      if (attachments.length > 0) {
        for (let i = 0; i < attachments.length; i++) {
          const file = attachments[i];
          formData.append("files.application[0].attachments", file, file.name);
        }
      }

      if (applicationForm?.nomination?.application_form === FORM_TYPES.TEAM) {
        setFullName(data.application[0].members[0].full_name);
      } else {
        setFullName(data.application[0].full_name);
      }

      formData.append("data", JSON.stringify(data));
      request.open("POST", `${getBackendUrl()}/api/nomination-applications`);
      request.send(formData);

      return new Promise((resolve, reject) => {
        request.onload = () => {
          if (request.status < 400) {
            return resolve();
          } else {
            return reject(new Error(request.responseText));
          }
        };

        request.onerror = (error) => {
          reject(error);
        };
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function handleSubmit(values) {
    try {
      const scope = new Sentry.Scope();
      scope.setTag("form", "nominationa_application");
      scope.setTag("form_type", applicationForm?.nomination?.application_form);
      scope.setContext("form_data", {
        ...values,
        attachments: (values.attachments || []).map((file) => file.name),
      });

      await sendApplication(values);
      dispatch(formSentSuccessfully());
    } catch (e) {
      Sentry.captureException(e, scope);
      dispatch(formSentWithFailures());
    }
  }

  function handleCloseSuccessMessage() {
    dispatch(resetForm());
    onClose();
  }

  function handleCloseErrorMessage() {
    dispatch(resetForm());
    onClose();
  }

  function handleSelectChange(option) {
    setSelectedNominationValue(option.value);
  }

  function getSelectValue() {
    return formatNominationOptions().find(
      (item) => item.value === selectedNominationValue
    );
  }

  function renderForms() {
    switch (applicationForm?.nomination?.application_form) {
      case FORM_TYPES.PERSONAL:
        return <PersonalApplicationForm onSubmit={handleSubmit} />;
      case FORM_TYPES.MANAGEMENT:
        return <ManagementApplicationForm onSubmit={handleSubmit} />;
      case FORM_TYPES.TEAM:
        return <TeamApplicationForm onSubmit={handleSubmit} />;

      default:
        break;
    }
  }

  const formPartners = partners
    .filter(
      (partnerBlock) =>
        partnerBlock.__typename ===
        "ComponentAwardPartnershipsSpecialPartnership"
    )
    .map((partnerBlock) => partnerBlock.SpecialPartner)
    .reduce((specialPartners, specialPartner) => {
      const partners = specialPartner.map((block) => block.partner);
      return specialPartners.concat(...partners);
    }, []);

  return (
    <Drawer
      isOpened={isOpened}
      onClose={onClose}
      className={styles.formWrap}
      closeOnOutsideClick={false}
    >
      <div className={styles.container}>
        <h2>{translate("application_form")}</h2>

        {formState === FORM_STATES.ACTIVE && (
          <React.Fragment>
            <div className={styles.nominations}>
              <div className={styles.nominationsBlock}>
                <Select
                  options={formatNominationOptions()}
                  className={styles.nominationsSelect}
                  placeholder={translate("nominations")}
                  name="nomination"
                  value={getSelectValue()}
                  onChange={handleSelectChange}
                  errorClassName={styles.error}
                />
              </div>
            </div>

            {renderForms()}

            {formPartners ? (
              <div className={styles.partners}>
                {formPartners.map((partner) => (
                  <a
                    key={partner.id}
                    href={partner.url}
                    className={styles.partnersLink}
                  >
                    <img src={Config.buildAssetsPath(partner.logo?.url)} />
                  </a>
                ))}
              </div>
            ) : null}
          </React.Fragment>
        )}

        {formState === FORM_STATES.ACCEPTED && (
          <ApplyAccepted
            applicationType="nominations"
            onClose={handleCloseSuccessMessage}
            fullName={fullName}
          />
        )}

        {formState === FORM_STATES.FAILED && (
          <ErrorApplication onClose={handleCloseErrorMessage} />
        )}
      </div>
    </Drawer>
  );
}

export default ApplicationForm;
