import React from "react";
import Icon from "components/Icon";

import { useTranslations } from "lib/translations";

import styles from "./index.module.scss";

function ErrorApplication({ onClose }) {
  const { translate } = useTranslations();

  function handleClose() {
    onClose();
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerWrap}>
        <h2>{translate("error.title")}</h2>
        <Icon className={styles.containerIcon} iconName="close" />
        <div className={styles.containerText}>
          <p className={styles.containerTextJury}>
            {translate("error.subtitle")}
          </p>
          <p className={styles.containerTextJury}>
            {translate("error.subtitle2")}
          </p>
        </div>
        <button onClick={handleClose} className={styles.containerBtn}>
          <Icon className={styles.containerBtnIcon} iconName="close" />{" "}
          {translate("success_send_close")}
        </button>
      </div>
    </div>
  );
}

export default ErrorApplication;
