import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import Link from "next/link";
import classnames from "classnames";
import Icon from "components/Icon";
import { useTranslations } from "lib/translations";
import throttle from "infrastructure/throttle";

import styles from "./index.module.scss";

function Header({
  allowSendingNominationApplications,
  allowSendingJuryApplications,
  onOpenJuryApplicationForm,
  onOpenApplicationForm,
  locale,
}) {
  const { translate } = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const [isNavShow, setIsNavShow] = useState(false);
  const sliderRef = useRef(null);
  const [btnHover, setBtnHover] = useState(false);

  function handleOpen() {
    setIsOpen(!isOpen);
  }

  function handleNavClick(element) {
    if (element) {
      if (document.body.classList.contains("isMobMenuOpen")) {
        setIsOpen(false);
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("isMobMenuOpen");
    }

    return () => {
      if (isOpen) {
        document.body.classList.remove("isMobMenuOpen");
      }
    };
  });

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.scrollY > 400) {
        setIsNavShow(true);
      } else {
        setIsNavShow(false);
      }
    }, 300);

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    autoplaySpeed: 4000,
    arrows: false,
  };

  return (
    <header className={styles.container}>
      <div
        className={classnames(styles.top, {
          [styles.isMobMenuOpen]: isOpen,
        })}
      >
        <a href="/" className={styles.logoWrap}>
          <img className={styles.logo} src="/logo-white.png" alt="logo" />
          <img
            className={classnames(styles.logo, styles.logoBlack)}
            src="/logo-black.png"
            alt="logo"
          />
        </a>

        <div className={styles.menuWrap}>
          <menu className={styles.menu}>
            <li className={styles.menuItem}>
              <a href="#info" onClick={handleNavClick}>
                {translate("header.about_the_award")}
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href="#nominations" onClick={handleNavClick}>
                {translate("header.nominations")}
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href="#jury" onClick={handleNavClick}>
                {translate("header.jury")}
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href="#rules" onClick={handleNavClick}>
                {translate("header.rules")}
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href="#people" onClick={handleNavClick}>
                {translate("header.participants")}
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href="#gallery" onClick={handleNavClick}>
                {translate("header.gallery")}
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href="#partners" onClick={handleNavClick}>
                {translate("header.partners")}
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href="#feedback" onClick={handleNavClick}>
                {translate("header.feedback")}
              </a>
            </li>
          </menu>
        </div>

        {allowSendingNominationApplications && (
          <button className={styles.topBtn} onClick={onOpenApplicationForm}>
            <span>{translate("submit_application")}</span>
            <Icon iconName="arrow" className={styles.topBtnIcon} />
          </button>
        )}

        <button className={styles.menuBtn} onClick={handleOpen}>
          <span></span>
          <span></span>
        </button>

        <div className={styles.langs}>
          <Link href="/" locale={"en"}>
            <a
              className={classnames(styles.langsItem, {
                [styles.isActive]: locale === "en",
              })}
            >
              En
            </a>
          </Link>
          <Link href="/" locale={"ua"}>
            <a
              className={classnames(styles.langsItem, {
                [styles.isActive]: locale === "ua",
              })}
            >
              Ua
            </a>
          </Link>
        </div>
      </div>

      <div
        className={classnames(styles.top, styles.navFixed, {
          [styles.isShow]: isNavShow,
        })}
      >
        <a href="/" className={styles.logoWrap}>
          <img className={styles.logo} src="/logo-white.png" alt="logo" />
          <img
            className={classnames(styles.logo, styles.logoBlack)}
            src="/logo-black.png"
            alt="logo"
          />
        </a>

        <div className={styles.menuWrap}>
          <menu className={styles.menu}>
            <li className={styles.menuItem}>
              <a href="#info" onClick={handleNavClick}>
                {translate("header.about_the_award")}
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href="#nominations" onClick={handleNavClick}>
                {translate("header.nominations")}
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href="#jury" onClick={handleNavClick}>
                {translate("header.jury")}
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href="#rules" onClick={handleNavClick}>
                {translate("header.rules")}
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href="#people" onClick={handleNavClick}>
                {translate("header.participants")}
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href="#gallery" onClick={handleNavClick}>
                {translate("header.gallery")}
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href="#partners" onClick={handleNavClick}>
                {translate("header.partners")}
              </a>
            </li>
            <li className={styles.menuItem}>
              <a href="#feedback" onClick={handleNavClick}>
                {translate("header.feedback")}
              </a>
            </li>
          </menu>
        </div>

        {allowSendingNominationApplications && (
          <button className={styles.topBtn} onClick={onOpenApplicationForm}>
            <span>{translate("submit_application")}</span>
            <Icon iconName="arrow" className={styles.topBtnIcon} />
          </button>
        )}

        <button className={styles.menuBtn} onClick={handleOpen}>
          <span></span>
          <span></span>
        </button>

        <div className={styles.langs}>
          <Link href="/" locale="en">
            <a
              className={classnames(styles.langsItem, {
                [styles.isActive]: locale === "en",
              })}
            >
              En
            </a>
          </Link>
          <Link href="/" locale="ua">
            <a
              className={classnames(styles.langsItem, {
                [styles.isActive]: locale === "ua",
              })}
            >
              Ua
            </a>
          </Link>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.contentWrap}>
          <div className={styles.contentTop}>
            <h1>
              Ukrainian <br /> IT Awards
            </h1>
          </div>

          <div className={styles.contentMiddle}>
            <div className={styles.info}>
              <p className={styles.infoLabel}>
                {translate("header.final_label")}
              </p>
              <p className={styles.infoData}>
                {translate("header.final_date")}
              </p>
            </div>
            <div className={styles.info}>
              <p className={styles.infoLabel}>
                {translate("header.place_label")}
              </p>
              <p className={styles.infoData}>
                <a
                  href="https://pmhub.io/
"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translate("header.place")}
                </a>
              </p>
            </div>
          </div>

          <div className={styles.contentBottom}>
            {allowSendingNominationApplications && (
              <button
                onMouseEnter={(e) => {
                  sliderRef.current.slickGoTo(1);
                  sliderRef.current.slickPause();
                  setBtnHover(true);
                }}
                onMouseLeave={() => {
                  sliderRef.current.slickPlay();
                  setBtnHover(false);
                }}
                className={classnames(styles.btn, styles.btnApply)}
                onClick={() => {
                  setBtnHover(false);
                  onOpenApplicationForm();
                }}
              >
                <span>{translate("submit_application")}</span>
                <Icon iconName="arrow" className={styles.btnApplyIcon} />
              </button>
            )}
            {allowSendingJuryApplications && (
              <button
                onMouseEnter={(e) => {
                  sliderRef.current.slickGoTo(2);
                  sliderRef.current.slickPause();
                  setBtnHover(true);
                }}
                onMouseLeave={() => {
                  sliderRef.current.slickPlay();
                  setBtnHover(false);
                }}
                onClick={() => {
                  setBtnHover(false);
                  onOpenJuryApplicationForm();
                }}
                className={classnames(styles.btn, styles.btnForm)}
              >
                <span>{translate("become_jury")}</span>
                <Icon iconName="arrow" className={styles.btnFormIcon} />
              </button>
            )}
          </div>
        </div>
      </div>

      <Slider {...settings} ref={sliderRef} className={styles.slider}>
        <img src="/slider-1.jpg" className={styles.sliderImg} />
        <img src="/slider-2.jpg" className={styles.sliderImg} />
        <img src="/slider-3.jpg" className={styles.sliderImg} />
      </Slider>

      <div
        className={classnames(styles.pixels, {
          [styles.isBtnHover]: btnHover,
        })}
      >
        <div className={styles.pixel}></div>
        <div className={styles.pixel}></div>
        <div className={styles.pixel}></div>
        <div className={styles.pixel}></div>
        <div className={styles.pixel}></div>
      </div>

      <div
        className={classnames(styles.pixelsBottom, {
          [styles.isBtnHover]: btnHover,
        })}
      >
        <div className={styles.pixel}></div>
        <div className={styles.pixel}></div>
        <div className={styles.pixel}></div>
        <div className={styles.pixel}></div>
        <div className={styles.pixel}></div>
        <div className={styles.pixel}></div>
        <div className={styles.pixel}></div>
      </div>
    </header>
  );
}

export default Header;
