function validation(values) {
  const errors = {};
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneRegex = /[0-9]{7}/gi;
  if (!values.name) {
    errors.name = "errors.require_name"; //"Будь ласка введіть ім'я";
  }
  if (!values.company) {
    errors.company = "errors.required_company"; // "Будь ласка введіть назву компанії";
  }
  if (!values.phone) {
    errors.phone = "errors.required_phone"; // "Будь ласка введіть контактний номер телефону";
  }
  if (!phoneRegex.test(values.phone)) {
    errors.phone = "errors.valid_phone"; //"Будь ласка введіть валідний контактний номер телефону";
  }

  if (!values.email) {
    errors.email = "errors.required_email"; //"Будь ласка введіть електронну пошту";
  }

  if (!emailRegex.test(values.email)) {
    errors.email = "errors.valid_email"; //"Будь ласка введіть валідну електронну пошту";
  }

  if (!values.message) {
    errors.message = "errors.required_message"; //"Будь ласка введіть повідомлення";
  }

  return errors;
}

export default validation;
