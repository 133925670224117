import React, { useMemo } from "react";
import NominationItem from "components/NominationItem";
import { useTranslations } from "lib/translations";

import styles from "./index.module.scss";

function applicaitonsCount(nomination, nominationApplicationsCounts) {
  const aggregate = nominationApplicationsCounts.find(
    (item) => item.key === nomination.id
  );

  if (aggregate) {
    return aggregate.count ?? 0;
  }

  return 0;
}

function Nominations({
  nominations,
  nominationApplicationsCounts,
  disableApplications,
  disableApplicationsCount,
  onOpenNominationsForm,
}) {
  const { translate } = useTranslations();

  const { peronal, team } = useMemo(
    () => ({
      peronal: nominations.filter(
        ({ nomination }) => nomination.kind === "personal"
      ),
      team: nominations.filter(({ nomination }) => nomination.kind === "team"),
    }),
    [nominations]
  );

  return (
    <section className={styles.container} id="nominations">
      <div className={styles.containerWrap}>
        <h2>{translate("nominations.header")}</h2>

        <p className={styles.description}>
          {translate("nominations.description")}
        </p>

        {peronal.length > 0 ? (
          <div className={styles.block}>
            <div className={styles.blockTop}>
              <h3>Individual nominations</h3>

              <p className={styles.blockDescription}>
                {translate("nominations.professional_description")}
              </p>
            </div>
            <div className={styles.blockHeadline}>
              <p className={styles.blockSubtitle}>{translate("nominations")}</p>
              <p className={styles.blockSubdescription}>
                {disableApplicationsCount
                  ? null
                  : translate("nominations.applied_applications")}
              </p>
            </div>

            <div className={styles.blockContent}>
              {peronal.map(({ nomination, juries }) => (
                <NominationItem
                  key={nomination.id}
                  onOpenNominationsForm={onOpenNominationsForm}
                  count={applicaitonsCount(
                    nomination,
                    nominationApplicationsCounts
                  )}
                  nomination={nomination}
                  disableApplications={disableApplications}
                  disableApplicationsCount={disableApplicationsCount}
                  juries={juries}
                />
              ))}
            </div>
          </div>
        ) : null}

        {team.length > 0 ? (
          <div className={styles.block}>
            <div className={styles.blockTop}>
              <h3>Team nominations</h3>
              <p className={styles.blockDescription}>
                {translate("nominations.team_description")}
              </p>
            </div>
            <div className={styles.blockHeadline}>
              <p className={styles.blockSubtitle}>{translate("nominations")}</p>
              <p className={styles.blockSubdescription}>
                {disableApplicationsCount
                  ? null
                  : translate("nominations.applied_applications")}
              </p>
            </div>

            <div className={styles.blockContent}>
              {team.map(({ nomination, juries }) => (
                <NominationItem
                  key={nomination.id}
                  nomination={nomination}
                  onOpenNominationsForm={onOpenNominationsForm}
                  juries={juries}
                  disableApplications={disableApplications}
                  disableApplicationsCount={disableApplicationsCount}
                  count={applicaitonsCount(
                    nomination,
                    nominationApplicationsCounts
                  )}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
}

Nominations.defaultProps = {
  nominations: [],
  nominationApplicationsCounts: [],
  disableApplications: false,
  disableNominationApplicationsCount: false,
};

export default Nominations;
