function generateListLabel({ descriptionText, textList = [] }) {
  return (
    <div>
      {descriptionText}
      <p>Please provide:</p>
      <ul>
        {textList.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
  );
}

export function AdvancedManagementPracticesToManageConstraints() {
  return generateListLabel({
    descriptionText:
      "Describe how did you use advanced management practices to manage constraints (scope, schedule, cost, etc.).",
    textList: [
      "- Context of the case (Project/Program/Portfolio description)?",
      "- Challenge/Complexity/Objectives, why this case is unique?",
      "- Solution/Applied practice/Activities - what have you done to address the challenge?",
      "- Outcomes/Results/Deliveries - what have you achieved?",
    ],
  });
}

export function AdvancedManagementPracticesToManageStrategyAndGoals() {
  return generateListLabel({
    descriptionText:
      "Describe how did you use advanced management practices to manage Strategy and Goals.",
    textList: [
      "- Context of the case (Project/Program/Portfolio description)?",
      "- Challenge/Complexity/Objectives, why this case is unique?",
      "- Solution/Applied practice/Activities - what have you done to address the challenge?",
      "- Outcomes/Results/Deliveries - what have you achieved?",
    ],
  });
}

export function AdvancedManagementPracticesToManageLifeCycle() {
  return generateListLabel({
    descriptionText:
      "Describe how did you use advanced management practices to manage Life Cycle (iterative/plan-driven).",
    textList: [
      "- Context of the case (Project/Program/Portfolio description)?",
      "- Challenge/Complexity/Objectives, why this case is unique?",
      "- Solution/Applied practice/Activities - what have you done to address the challenge?",
      "- Outcomes/Results/Deliveries - what have you achieved?",
    ],
  });
}

export function AdvancedManagementPracticesToManageValueAndBenefits() {
  return generateListLabel({
    descriptionText:
      "Describe how did you use advanced management practices to manage value and benefits.",
    textList: [
      "- Context of the case (Project/Program/Portfolio description)?",
      "- Challenge/Complexity/Objectives, why this case is unique?",
      "- Solution/Applied practice/Activities - what have you done to address the challenge?",
      "- Outcomes/Results/Deliveries - what have you achieved?",
    ],
  });
}

export function AdvancedManagementPracticesToManageCommunicationAndStakeholders() {
  return generateListLabel({
    descriptionText:
      "Describe how did you use advanced management practices to manage Communication and Stakeholders.",
    textList: [
      "- Context of the case (Project/Program/Portfolio description)?",
      "- Challenge/Complexity/Objectives, why this case is unique?",
      "- Solution/Applied practice/Activities - what have you done to address the challenge?",
      "- Outcomes/Results/Deliveries - what have you achieved?",
    ],
  });
}

export function DescribeEstablishingGovernance() {
  return generateListLabel({
    descriptionText: "Describe how did you establish governance.",
    textList: [
      "- Context of the case (Project/Program/Portfolio description)?",
      "- Challenge/Complexity/Objectives, why this case is unique?",
      "- Solution/Applied practice/Activities - what have you done to address the challenge?",
      "- Outcomes/Results/Deliveries - what have you achieved?",
    ],
  });
}

export function DescribeManagingQuality() {
  return generateListLabel({
    descriptionText: "Describe how did you manage quality.",
    textList: [
      "- Context of the case (Project/Program/Portfolio description)?",
      "- Challenge/Complexity/Objectives, why this case is unique?",
      "- Solution/Applied practice/Activities - what have you done to address the challenge?",
      "- Outcomes/Results/Deliveries - what have you achieved?",
    ],
  });
}

export function DescribeManagingResources() {
  return generateListLabel({
    descriptionText: "Describe how did you manage people/human resources.",
    textList: [
      "- Context of the case (Project/Program/Portfolio description)?",
      "- Challenge/Complexity/Objectives, why this case is unique?",
      "- Solution/Applied practice/Activities - what have you done to address the challenge?",
      "- Outcomes/Results/Deliveries - what have you achieved?",
    ],
  });
}

export function DescribeManagingRisks() {
  return generateListLabel({
    descriptionText: " Describe how did you manage risks.",
    textList: [
      "- Context of the case (Project/Program/Portfolio description)?",
      "- Challenge/Complexity/Objectives, why this case is unique?",
      "- Solution/Applied practice/Activities - what have you done to address the challenge?",
      "- Outcomes/Results/Deliveries - what have you achieved?",
    ],
  });
}

export function Achivements() {
  return (
    <div>
      Please list down your Education, Awards, certifications, Social
      Responsibility and community development (Articles, Conferences, Courses,
      etc.).
    </div>
  );
}
