import React from "react";
import Dropzone from "react-dropzone";
import Icon from "components/Icon";
import classNames from "classnames";

import styles from "./styles";

function FileInput({
  placeholder,
  name,
  label,
  icon,
  multiple,
  value,
  onChange,
  touched,
  error,
  errorClassName,
}) {
  const [loadedFiles, setFiles] = React.useState([]);

  function handleRemove(event, item) {
    event.stopPropagation();
    const filteredFiles = loadedFiles.filter((file) => file.name !== item.name);
    setFiles(filteredFiles);
    onChange("attachments", filteredFiles);
  }

  return (
    <React.Fragment>
      <Dropzone
        // maxSize={maxImageSize}
        multiple={multiple}
        onDrop={(files) => {
          if (value.length > 0) {
            setFiles([...value, ...files]);
            onChange("attachments", [...value, ...files]);
            return;
          }
          setFiles(files);
          onChange("attachments", files);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section style={styles.root}>
            <div {...getRootProps()} style={styles.outline}>
              <input {...getInputProps()} name={name} style={styles.input} />
              {icon ? icon : null}
              <div style={styles.files}>
                {loadedFiles.length > 0 ? (
                  loadedFiles.map((item) => (
                    <div style={styles.fileName}>
                      {item.name}  
                      <Icon
                        iconName={"close"}
                        onClick={(e) => handleRemove(e, item)}
                        style={styles.iconClose}
                      />
                    </div>
                  ))
                ) : (
                  <label style={styles.label}>{placeholder || label}</label>
                )}
              </div>
            </div>
            {error && touched ? (
              <span
                style={{
                  position: "absolute",
                  fontSize: "10px",
                  color: "#ff8a00",
                }}
                className={classNames(styles.error, {
                  [errorClassName]: !!errorClassName,
                })}
              >
                {error}
              </span>
            ) : null}
          </section>
        )}
      </Dropzone>
    </React.Fragment>
  );
}

export default FileInput;
