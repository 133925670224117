import React, { useEffect } from "react";
import Drawer from "components/Drawer";
import { buildAssetsPath } from "lib/config";
import { useTranslations } from "lib/translations";

import styles from "./index.module.scss";

function PersonDrawer({ isOpened, onClose, person }) {
  const { getLocaleField } = useTranslations();
  return (
    <Drawer isOpened={isOpened} onClose={onClose}>
      <div className={styles.person}>
        <img
          src={buildAssetsPath(person?.photo?.url)}
          className={styles.personPhoto}
        />
        <div className={styles.personInfo}>
          <p className={styles.personInfoName}>
            {getLocaleField(person, "full_name")}
          </p>
          <p className={styles.personInfoNomination}>
            {person?.nomination?.title}
          </p>
          <p className={styles.personInfoCompany}>
            {getLocaleField(person, "company")}
          </p>
          <p className={styles.personInfoDescription}>
            {getLocaleField(person, "description")}
          </p>
        </div>
      </div>
    </Drawer>
  );
}

export default PersonDrawer;
