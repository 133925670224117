import React from "react";
import Icon from "components/Icon";

import { useTranslations } from "lib/translations";

import styles from "./index.module.scss";

function ApplyAccepted({ onClose, fullName, applicationType }) {
  const { translate } = useTranslations();

  function handleClose() {
    onClose();
  }

  function renderJurySuccessMessage() {
    return (
      <p className={styles.containerTextJury}>
        {translate("success_send_thank")}, <span>{fullName}</span>,{" "}
        {translate("success_send_thank_2_jury")}.{" "}
        {translate("success_send_candidature_1_jury")}{" "}
        <span>{translate("success_send_days_count_jury")}</span>{" "}
        {translate("success_send_candidature_2_jury")}
      </p>
    );
  }

  function renderNominantSuccessMessage() {
    return (
      <p className={styles.containerTextNominant}>
        <p>
          {translate("success_send_thank")}, <span>{fullName}</span>,{" "}
          {translate("success_send_thank_2_nominant")}
        </p>
        <p>{translate("success_send_application_confirmed")}</p>
        <p>{translate("success_send_dates_nominant")}</p>
        <p>{translate("success_send_results_nominant")}</p>
        <br />
        <p>{translate("success_send_good_luck")}</p>
        <br />
        <p>{translate("success_send_last_paragraph")}</p>
      </p>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerWrap}>
        <Icon className={styles.containerIcon} iconName="success" />
        <div className={styles.containerText}>
          {applicationType === "jury"
            ? renderJurySuccessMessage()
            : renderNominantSuccessMessage()}
        </div>
        <button onClick={handleClose} className={styles.containerBtn}>
          <Icon className={styles.containerBtnIcon} iconName="close" />{" "}
          {translate("success_send_close")}
        </button>
      </div>
    </div>
  );
}

export default ApplyAccepted;
