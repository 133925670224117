function validate(values) {
  const errors = {};
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  errors.members = [];

  if (!values.company) {
    errors.company = "errors.required_company"; // "Будь ласка введіть назву компанії";
  }

  if (!values.describe_company) {
    errors.describe_company = "errors.application_requied_field"; // "Будь ласка введіть назву компанії";
  }

  if (!values.describe_recognition) {
    errors.describe_recognition = "errors.application_requied_field";
  }

  if (!values.describe_impact) {
    errors.describe_impact = "errors.application_requied_field";
  }

  if (!values.why_should_receive_nomination) {
    errors.why_should_receive_nomination = "errors.application_requied_field";
  }

  if (!values.agreement) {
    errors.agreement = "erros.required_agreement";
  }

  values.members.forEach((member, index) => {
    if (!member.full_name) {
      errors.members[index] = {
        ...errors.members[index],
        full_name: "errors.required_full_name",
      };
    }
    if (!member.position) {
      errors.members[index] = {
        ...errors.members[index],
        position: "errors.required_position",
      };
    }
    if (!member.email) {
      errors.members[index] = {
        ...errors.members[index],
        email: "errors.required_email",
      }; // "Будь ласка введіть контактний номер телефону";

      if (!emailRegex.test(member.email)) {
        errors.members[index] = {
          ...errors.members[index],
          email: "errors.valid_email",
        }; //"Будь ласка введіть валідну електронну пошту";
      }
    }

    if (!member.phone_number) {
      errors.members[index] = {
        ...errors.members[index],
        phone_number: "errors.required_phone_number",
      }; // "Будь ласка введіть контактний номер телефону";
    }

    if (!member.linkedin) {
      errors.members[index] = {
        ...errors.members[index],
        linkedin: "errors.required_linkedin",
      }; // "Будь ласка введіть назву компанії";
    }

    if (!member.areas_of_responsibilities) {
      errors.members[index] = {
        ...errors.members[index],
        areas_of_responsibilities: "errors.application_requied_field",
      };
    }
  });

  if (errors.members.length === 0) {
    delete errors.members;
  }

  return errors;
}

export default validate;
