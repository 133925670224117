export function getBackendUrl() {
  if (process.env.NODE_ENV === "development") {
    return "https://itawards.ua";
  }

  if (typeof window === "undefined") {
    return "http://172.17.0.1:1337";
  }

  return window.location.origin;
}

export function buildAssetsPath(relativePath = "") {
  if (process.env.NODE_ENV === "development") {
    return `${getBackendUrl()}${relativePath}`;
  }

  return relativePath;
}
