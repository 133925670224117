import React, { useEffect, useMemo, useState } from "react";
import YearButton from "components/YearButton";
import Person from "components/Person";
import * as Config from "lib/config";
import PersonDrawer from "components/PersonDrawer";
import { useTranslations } from "lib/translations";

import styles from "./index.module.scss";

function AwardPeopleBlock({
  title,
  awardPositionLabel,
  awardPeople,
  avatarSize,
}) {
  const filteredAwardPeople = useMemo(
    () =>
      awardPeople.filter((ap) => {
        const [award, people] = ap;
        return people && people.length > 0;
      }),
    [awardPeople]
  );

  const initSelectedAwardPeople = useMemo(() => {
    if (filteredAwardPeople && filteredAwardPeople.length) {
      return (
        filteredAwardPeople[filteredAwardPeople.length - 1] ?? [null, undefined]
      );
    }

    return [null, undefined];
  }, [filteredAwardPeople]);

  const { getLocaleField } = useTranslations();
  const [selectedAwardPeople, selectAwardPeople] = useState(
    initSelectedAwardPeople
  );
  const [selectedAward, selectedPeople] = selectedAwardPeople ?? [null, []];
  const [isPersonDrawerOpened, setIsPersonDrawerOpend] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);

  return (
    <React.Fragment>
      <PersonDrawer
        isOpened={isPersonDrawerOpened}
        onClose={() => setIsPersonDrawerOpend(false)}
        person={selectedPerson}
      />
      <div className={styles.container}>
        <h3>{title}</h3>

        <div className={styles.years}>
          {awardPeople.map((item) => {
            const [award, persons] = item;

            return persons.length > 0 ? (
              <YearButton
                key={award.title}
                className={styles.yearsItem}
                title={award.title}
                isActive={award === selectedAward}
                onClick={() => selectAwardPeople(item)}
              />
            ) : null;
          })}
        </div>

        <div className={styles.participants}>
          {selectedPeople.map((person) => (
            <Person
              key={person.id}
              onClick={() => {
                setSelectedPerson(person);
                setIsPersonDrawerOpend(true);
              }}
              size={avatarSize}
              fullName={getLocaleField(person, "full_name")}
              company={getLocaleField(person, "company")}
              nomination={person.nomination.title}
              awardPosition={awardPositionLabel}
              img={Config.buildAssetsPath(
                person.photo?.formats?.small?.url || person.photo?.url
              )}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default AwardPeopleBlock;
