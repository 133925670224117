function validate(values) {
  const errors = {};
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!values.full_name) {
    errors.full_name = "errors.required_full_name";
  }

  if (!values.company) {
    errors.company = "errors.required_company"; // "Будь ласка введіть назву компанії";
  }

  if (!values.phone_number) {
    errors.phone_number = "errors.required_phone_number"; // "Будь ласка введіть контактний номер телефону";
  }

  if (!values.email) {
    errors.email = "errors.required_email"; // "Будь ласка введіть контактний номер телефону";
  }

  if (!emailRegex.test(values.email)) {
    errors.email = "errors.valid_email"; //"Будь ласка введіть валідну електронну пошту";
  }

  if (!values.position) {
    errors.position = "errors.required_position"; // "Будь ласка введіть назву компанії";
  }

  if (!values.linkedin) {
    errors.linkedin = "errors.required_linkedin"; // "Будь ласка введіть назву компанії";
  }

  if (!values.advanced_management_practices_to_manage_constraints) {
    errors.advanced_management_practices_to_manage_constraints =
      "errors.application_requied_field"; // "Будь ласка введіть назву компанії";
  }

  if (!values.advanced_management_practices_to_manage_strategy_and_goals) {
    errors.advanced_management_practices_to_manage_strategy_and_goals =
      "errors.application_requied_field";
  }

  if (!values.advanced_management_practices_to_manage_life_cycle) {
    errors.advanced_management_practices_to_manage_life_cycle =
      "errors.application_requied_field";
  }

  if (!values.advanced_management_practices_to_manage_value_and_benefits) {
    errors.advanced_management_practices_to_manage_value_and_benefits =
      "errors.application_requied_field";
  }

  if (
    !values.advanced_management_practices_to_manage_communication_and_stakeholders
  ) {
    errors.advanced_management_practices_to_manage_communication_and_stakeholders =
      "errors.application_requied_field";
  }

  if (!values.describe_establishing_governance) {
    errors.describe_establishing_governance =
      "errors.application_requied_field";
  }

  if (!values.describe_managing_quality) {
    errors.describe_managing_quality = "errors.application_requied_field";
  }

  if (!values.describe_managing_resources) {
    errors.describe_managing_resources = "errors.application_requied_field";
  }

  if (!values.describe_managing_risks) {
    errors.describe_managing_risks = "errors.application_requied_field";
  }

  if (!values.achivments) {
    errors.achivments = "errors.application_requied_field";
  }

  if (!values.agreement) {
    errors.agreement = "erros.application_requied_field";
  }
  return errors;
}

export default validate;
