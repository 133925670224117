import React from "react";
import { useTranslations } from "lib/translations";
import ReasonsItem from "components/ReasonsItem";

import styles from "./index.module.scss";

function Reasons() {
  const { translate } = useTranslations();
  return (
    <section className={styles.container}>
      <div className={styles.containerWrap}>
        <h3>{translate("reasons.title")}</h3>

        <div className={styles.list}>
          <ReasonsItem
            title={translate("reasons.item_feedback_title")}
            text={translate("reasons.item_feedback_text")}
            iconName="reason-feedback"
          />
          <ReasonsItem
            title={translate("reasons.item_reputation_title")}
            text={translate("reasons.item_reputation_text")}
            iconName="reason-reputation"
          />
          <ReasonsItem
            title={translate("reasons.item_recognition_title")}
            text={translate("reasons.item_recognition_text")}
            iconName="reason-recognition"
          />
          <ReasonsItem
            title={translate("reasons.item_knowledges_title")}
            text={translate("reasons.item_knowledges_text")}
            iconName="reason-knowledges"
          />
          <ReasonsItem
            title={translate("reasons.item_solutions_title")}
            text={translate("reasons.item_solutions_text")}
            iconName="reason-solutions"
          />
          <ReasonsItem
            title={translate("reasons.item_networking_title")}
            text={translate("reasons.item_networking_text")}
            iconName="reason-networking"
          />
        </div>
      </div>
    </section>
  );
}

export default Reasons;
