import React from "react";
import FormField from "components/FormField";
import Icon from "components/Icon";
import TextArea from "components/TextArea";
import { useFormik } from "formik";
import FileInput from "components/FileLoader";
import { useTranslations } from "lib/translations";
import Loader from "components/Loader";

import {
  AdvancedManagementPracticesToManageConstraints,
  AdvancedManagementPracticesToManageStrategyAndGoals,
  AdvancedManagementPracticesToManageLifeCycle,
  AdvancedManagementPracticesToManageValueAndBenefits,
  AdvancedManagementPracticesToManageCommunicationAndStakeholders,
  DescribeEstablishingGovernance,
  DescribeManagingQuality,
  DescribeManagingResources,
  DescribeManagingRisks,
  Achivements,
} from "./ManagementLabels";
import validate from "./validation";

import styles from "./index.module.scss";

function ManagementApplicationForm({ onSubmit }) {
  const { translate } = useTranslations();

  const formik = useFormik({
    initialValues: {
      full_name: "",
      position: "",
      company: "",
      phone_number: "",
      email: "",
      linkedin: "",
      advanced_management_practices_to_manage_constraints: "",
      advanced_management_practices_to_manage_strategy_and_goals: "",

      advanced_management_practices_to_manage_life_cycle: "",
      advanced_management_practices_to_manage_value_and_benefits: "",
      advanced_management_practices_to_manage_communication_and_stakeholders:
        "",
      describe_establishing_governance: "",
      describe_managing_quality: "",
      describe_managing_resources: "",
      describe_managing_risks: "",
      achivments: "",
      attachments: [],
      agreement: false,
    },
    validate,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: async (values) => {
      const { agreement, ...data } = values;
      await onSubmit(data);
      formik.resetForm();
    },
  });

  function handleSubmit(e, values) {
    e.preventDefault();
    formik.handleSubmit(values);
  }

  return (
    <form autocomplete="off" onSubmit={handleSubmit}>
      <FormField
        className={styles.formField}
        inputProps={{ className: styles.formInput }}
        name="full_name"
        placeholder={translate("application_form_full_name")}
        onBlur={formik.handleBlur}
        value={formik.values.full_name}
        onChange={formik.handleChange}
        error={translate(formik.errors.full_name)}
        touched={formik.touched.full_name}
        errorClassName={styles.error}
      />

      <div className={styles.formGroup}>
        <FormField
          name="position"
          className={styles.formField}
          inputProps={{ className: styles.formInput }}
          placeholder={translate("application_form_position")}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.position}
          error={translate(formik.errors.position)}
          touched={formik.touched.position}
          errorClassName={styles.error}
        />
        <FormField
          name="company"
          value={formik.values.company}
          error={translate(formik.errors.company)}
          touched={formik.touched.company}
          className={styles.formField}
          onChange={formik.handleChange}
          placeholder={translate("application_form_company")}
          inputProps={{ className: styles.formInput }}
          onBlur={formik.handleBlur}
          errorClassName={styles.error}
        />
      </div>

      <div className={styles.formGroup}>
        <FormField
          name="email"
          className={styles.formField}
          inputProps={{ className: styles.formInput }}
          placeholder={translate("application_form_mail")}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={translate(formik.errors.email)}
          touched={formik.touched.email}
          errorClassName={styles.error}
        />
        <FormField
          name="phone_number"
          type="tel"
          className={styles.formField}
          placeholder={translate("application_form_phone_number")}
          inputProps={{ className: styles.formInput }}
          onBlur={formik.handleBlur}
          value={formik.values.phone_number}
          onChange={formik.handleChange}
          error={translate(formik.errors.phone_number)}
          touched={formik.touched.phone_number}
          errorClassName={styles.error}
        />
      </div>

      <FormField
        className={styles.formField}
        inputProps={{ className: styles.formInput }}
        name="linkedin"
        type="url"
        placeholder={translate("application_form_linkedin")}
        onBlur={formik.handleBlur}
        value={formik.values.linkedin}
        onChange={formik.handleChange}
        error={translate(formik.errors.linkedin)}
        touched={formik.touched.linkedin}
        errorClassName={styles.error}
      />

      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="advanced_management_practices_to_manage_constraints"
        placeholder={<AdvancedManagementPracticesToManageConstraints />}
        onBlur={formik.handleBlur}
        value={
          formik.values.advanced_management_practices_to_manage_constraints
        }
        onChange={formik.handleChange}
        error={translate(
          formik.errors.advanced_management_practices_to_manage_constraints
        )}
        touched={
          formik.touched.advanced_management_practices_to_manage_constraints
        }
        errorClassName={styles.error}
        cols={100}
        rows={1}
      />

      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="advanced_management_practices_to_manage_strategy_and_goals"
        placeholder={<AdvancedManagementPracticesToManageStrategyAndGoals />}
        onBlur={formik.handleBlur}
        value={
          formik.values
            .advanced_management_practices_to_manage_strategy_and_goals
        }
        onChange={formik.handleChange}
        error={translate(
          formik.errors
            .advanced_management_practices_to_manage_strategy_and_goals
        )}
        touched={
          formik.touched
            .advanced_management_practices_to_manage_strategy_and_goals
        }
        errorClassName={styles.error}
      />
      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="advanced_management_practices_to_manage_life_cycle"
        placeholder={<AdvancedManagementPracticesToManageLifeCycle />}
        onBlur={formik.handleBlur}
        value={formik.values.advanced_management_practices_to_manage_life_cycle}
        onChange={formik.handleChange}
        error={translate(
          formik.errors.advanced_management_practices_to_manage_life_cycle
        )}
        touched={
          formik.touched.advanced_management_practices_to_manage_life_cycle
        }
        errorClassName={styles.error}
      />
      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="advanced_management_practices_to_manage_value_and_benefits"
        placeholder={<AdvancedManagementPracticesToManageValueAndBenefits />}
        onBlur={formik.handleBlur}
        value={
          formik.values
            .advanced_management_practices_to_manage_value_and_benefits
        }
        onChange={formik.handleChange}
        error={translate(
          formik.errors
            .advanced_management_practices_to_manage_value_and_benefits
        )}
        touched={
          formik.touched
            .advanced_management_practices_to_manage_value_and_benefits
        }
        errorClassName={styles.error}
      />

      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="advanced_management_practices_to_manage_communication_and_stakeholders"
        placeholder={
          <AdvancedManagementPracticesToManageCommunicationAndStakeholders />
        }
        onBlur={formik.handleBlur}
        value={
          formik.values
            .advanced_management_practices_to_manage_communication_and_stakeholders
        }
        onChange={formik.handleChange}
        error={translate(
          formik.errors
            .advanced_management_practices_to_manage_communication_and_stakeholders
        )}
        touched={
          formik.touched
            .advanced_management_practices_to_manage_communication_and_stakeholders
        }
        errorClassName={styles.error}
      />

      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="describe_establishing_governance"
        placeholder={<DescribeEstablishingGovernance />}
        onBlur={formik.handleBlur}
        value={formik.values.describe_establishing_governance}
        onChange={formik.handleChange}
        error={translate(formik.errors.describe_establishing_governance)}
        touched={formik.touched.describe_establishing_governance}
        errorClassName={styles.error}
      />

      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="describe_managing_quality"
        placeholder={<DescribeManagingQuality />}
        onBlur={formik.handleBlur}
        value={formik.values.describe_managing_quality}
        onChange={formik.handleChange}
        error={translate(formik.errors.describe_managing_quality)}
        touched={formik.touched.describe_managing_quality}
        errorClassName={styles.error}
      />

      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="describe_managing_resources"
        placeholder={<DescribeManagingResources />}
        onBlur={formik.handleBlur}
        value={formik.values.describe_managing_resources}
        onChange={formik.handleChange}
        error={translate(formik.errors.describe_managing_resources)}
        touched={formik.touched.describe_managing_resources}
        errorClassName={styles.error}
      />

      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="describe_managing_risks"
        placeholder={<DescribeManagingRisks />}
        onBlur={formik.handleBlur}
        value={formik.values.describe_managing_risks}
        onChange={formik.handleChange}
        error={translate(formik.errors.describe_managing_risks)}
        touched={formik.touched.describe_managing_risks}
        errorClassName={styles.error}
      />

      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="achivments"
        placeholder={<Achivements />}
        onBlur={formik.handleBlur}
        value={formik.values.achivments}
        onChange={formik.handleChange}
        error={translate(formik.errors.achivments)}
        touched={formik.touched.achivments}
        errorClassName={styles.error}
      />

      <FileInput
        name="attachments"
        value={formik.values.attachments}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={translate(formik.errors.attachments)}
        touched={formik.touched.attachments}
        errorClassName={styles.error}
        value={formik.values.attachments}
        onChange={formik.setFieldValue}
        mutliple
        placeholder={translate("attach_files")}
        icon={<Icon iconName="clip" className={styles.formInputFileIcon} />}
      />

      <label className={styles.check}>
        <FormField
          type="checkbox"
          name="agreement"
          inputProps={{
            className: styles.formCheck,
          }}
          errorClassName={styles.error}
          onBlur={formik.handleBlur}
          onChange={() =>
            formik.setFieldValue("agreement", !formik.values.agreement)
          }
          value={formik.values.agreement}
        />
        <p className={styles.checkText}>
          {translate("application_form_agreement")}
        </p>
      </label>

      <button
        className={styles.formSubmit}
        type="submit"
        disabled={!formik.values.agreement || formik.isSubmitting}
      >
        {formik.isSubmitting ? (
          <React.Fragment>
            <spam>Sending</spam>
            <Loader />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span>{translate("application_submit")}</span>
            <Icon iconName="arrow" className={styles.formSubmitIcon} />
          </React.Fragment>
        )}
      </button>
      {formik.submitCount > 0 && !formik.isValid ? (
        <p className={styles.validationError}>
          {translate("errors.submit-message")}
        </p>
      ) : null}
    </form>
  );
}

export default ManagementApplicationForm;
