import React from "react";
import classNames from "classnames";

import styles from "./index.module.scss";

function FormField({
  className,
  name,
  error,
  touched,
  onChange,
  value,
  inputProps,
  placeholder,
  errorClassName,
  autoComplete,
  onBlur,
  label,
  type,
  icon,
}) {
  const [focused, setFocused] = React.useState(false);
  function handleFocus() {
    setFocused(true);
  }

  function handleBlur(e) {
    setFocused(false);
    onBlur(e);
  }
  return (
    <div
      className={classNames(styles.container, {
        [className]: !!className,
        [styles.isFocused]: focused || value,
        [styles.isError]: error && touched,
      })}
    >
      {icon ? icon : null}
      {placeholder || label ? (
        <label
          className={classNames(styles.label, {
            [styles.isError]: error && touched,
          })}
        >
          {placeholder || label}
        </label>
      ) : null}
      <input
        onFocus={handleFocus}
        name={name}
        type={type}
        onChange={onChange}
        value={value}
        onBlur={handleBlur}
        checked={value}
        autoComplete={autoComplete}
        {...inputProps}
        className={classNames({
          [inputProps.className]: !!inputProps.className,
          [styles.isError]: error && touched,
        })}
      />
      {error && touched ? (
        <span
          className={classNames(styles.error, {
            [errorClassName]: !!errorClassName,
          })}
        >
          {error}
        </span>
      ) : null}
    </div>
  );
}

FormField.defaultProps = {
  autoComplete: "off",
};

export default FormField;
