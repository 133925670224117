import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";

import styles from "./index.module.scss";

function Person({
  img,
  size,
  fullName,
  nomination,
  company,
  awardPosition,
  onClick,
}) {
  const [shouldRotate, setShouldRotate] = useState(false);
  const infoBlockRef = useRef();

  useEffect(() => {
    function handleCheckIsNeedRotate() {
      const elementEdgePosition =
        infoBlockRef.current.getBoundingClientRect().width +
        infoBlockRef.current.getBoundingClientRect().right;
      const innerWidth = window.innerWidth;

      if (elementEdgePosition > innerWidth) {
        setShouldRotate(true);
      } else {
        setShouldRotate(false);
      }
    }
    if (infoBlockRef.current) {
      handleCheckIsNeedRotate();
      window.addEventListener("resize", handleCheckIsNeedRotate);
    }

    return () => {
      window.removeEventListener("resize", handleCheckIsNeedRotate);
    };
  }, []);

  return (
    <div
      ref={infoBlockRef}
      className={classnames(styles.container, {
        [styles.lg]: size === "lg",
        [styles.md]: size === "md",
      })}
      onClick={onClick}
    >
      <img src={img} alt="person-photo" loading="lazy" className={styles.img} />
      <div
        className={classnames(styles.info, {
          [styles.infoRotate]: shouldRotate,
        })}
      >
        <p className={styles.name}>{fullName}</p>
        <p className={styles.label}>{awardPosition}</p>
        <p className={styles.nomination}>{nomination}</p>
        <p className={styles.workplace}>{company}</p>
      </div>
    </div>
  );
}

export default Person;
