function validate(values) {
  const errors = {};
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!values.full_name) {
    errors.full_name = "errors.required_full_name";
  }

  if (!values.company) {
    errors.company = "errors.required_company"; // "Будь ласка введіть назву компанії";
  }

  if (!values.phone_number) {
    errors.phone_number = "errors.required_phone_number"; // "Будь ласка введіть контактний номер телефону";
  }

  if (!values.email) {
    errors.email = "errors.required_email"; // "Будь ласка введіть контактний номер телефону";
  }

  if (!emailRegex.test(values.email)) {
    errors.email = "errors.valid_email"; //"Будь ласка введіть валідну електронну пошту";
  }

  if (!values.position) {
    errors.position = "errors.required_position"; // "Будь ласка введіть назву компанії";
  }

  if (!values.linkedin) {
    errors.linkedin = "errors.required_linkedin"; // "Будь ласка введіть назву компанії";
  }

  if (!values.skype) {
    errors.skype = "errors.required_skype"; // "Будь ласка введіть назву компанії";
  }

  if (!values.trends) {
    errors.trends = "errors.required_trends";
  }

  errors.nominations = [];
  values.nominations.forEach((item, index) => {
    if (!item.id) {
      errors.nominations[index] = "errors.required_nominations";
    }
  });

  if (errors.nominations.length === 0) {
    delete errors.nominations;
  }

  if (!values.professional_experience) {
    errors.professional_experience = "errors.required_professional_experience";
  }

  if (!values.crucial_personal_traits) {
    errors.crucial_personal_traits = "errors.required_crucial_personal_traits";
  }

  if (!values.motivation) {
    errors.motivation = "errors.required_motivation";
  }

  if (!values.agreement) {
    errors.agreement = "erros.required_agreement";
  }
  return errors;
}

export default validate;
