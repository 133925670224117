import * as React from "react";
import Icon from "components/Icon";

import styles from "./index.module.scss";

function VideoPreview({ src }) {
  return (
    <React.Fragment>
      <video
        style={{ display: "none" }}
        muted
        src={src}
        crossOrigin="anonymous"
      />
      <img src={"/preview.png"} alt="preview" />
      <Icon iconName="video" className={styles.segmentVideoIcon} />
    </React.Fragment>
  );
}

export default VideoPreview;
