const styles = {
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    "& > div": {
      position: "relative",
      color: "#000",
      padding: 0,
      height: "100%",
      width: 1,
      "& > svg": {
        display: "none",
      },
      ":after": {
        content: "''",
        position: "absolute",
        bottom: 0,
        top: 0,
        right: 10,
        margin: "auto",
        width: 16,
        height: 16,
        borderLeft: "2px solid #000",
        borderBottom: "2px solid #000",
        transform: "rotate(-45deg) translate(50%, 0)",
      },
    },
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    padding: 0,
    height: "100%",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#000",
    fontWeight: 500,
  }),
  option: (styles, state) => ({
    ...styles,
    padding: "0 16px",
    borderBottom: "1px solid #A21ED8",
    backgroundColor: state.isSelected ? "#F7E3FF" : "transparent",
    color: state.isSelected ? "#000" : "#000",
    ":hover": {
      backgroundColor: "#F7E3FF",
    },
  }),
  control: (styles, state) => ({
    ...styles,
    display: "flex",
    width: "100%",
    height: 62,
    boxShadow: 0,
    borderRadius: 0,
    border: state.isFocused ? "1px solid #A21ED8" : 0,
    backgroundColor: state.isFocused ? "#F7E3FF" : "transparent",
    color: state.isFocused ? "#000" : "#000",
    padding: state.isFocused ? "0 16px" : 0,
    zIndex: 2,
    ":hover": {
      borderColor: "#A21ED8",
    },
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: 0,
    margin: 0,
    zIndex: 10,
    top: "calc(100% + 1px)",
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
    maxHeight: 530,
    border: "1px solid #A21ED8",
    borderTop: 0,
  }),
  container: (styles) => ({
    ...styles,
    zIndex: "auto",
  }),
  singleValue: (styles) => ({
    ...styles,
    maxWidth: "calc(100% - 28px)",
  }),
};

export default styles;
