import * as React from "react";
import { getData } from "lib/grahpql/index";
import { loadTranslations } from "lib/translations";
import Layout from "components/Layout";
import Header from "components/Header";
import Info from "components/Info";
import Jury from "components/Jury";
import Rules from "components/Rules";
import Gallery from "components/Gallery";
import Partners from "components/Partners";
import Feedback from "components/Feedback";
import Footer from "components/Footer";
import Nominations from "components/Nominations";
import People from "components/People";
import ApplicationJuryForm from "components/ApplicationJuryForm";
import ApplicationForm from "components/ApplicationsForm";
import Reasons from "components/Reasons";

function IndexPage({
  awards,
  partners,
  nominations,
  current_award,
  allow_sending_jury_applications,
  allow_sending_nomination_applications,
  show_nomination_applies_count,
  facebook_url,
  nomination_applies_count,
  phone_number,
  gallery,
  rules_document_ua,
  rules_document_en,
  locale,
}) {
  const rulesDocument = React.useMemo(() => {
    switch (locale) {
      case "ua":
        return rules_document_ua?.url;
      case "en":
        return rules_document_en?.url;
      default:
        return rules_document_ua?.url;
    }
  }, [rules_document_ua, rules_document_en, locale]);

  const [
    openedApplicationJuryForm,
    setOpenedApplicationJuryForm,
  ] = React.useState(false);

  const [openedApplicationForm, setOpenedApplicationForm] = React.useState(
    false
  );

  const [
    selectedNominationOption,
    setSelectedNominationOption,
  ] = React.useState(undefined);

  function handleOpenApplicationJuryForm() {
    setOpenedApplicationJuryForm(true);
  }

  function handleCloseApplicationJuryForm() {
    setOpenedApplicationJuryForm(false);
  }

  function handleOpenApplicationForm() {
    setOpenedApplicationForm(true);
  }

  function handleCloseApplicationForm() {
    setOpenedApplicationForm(false);
  }

  function handleOpenApplicationByNomination(nomination) {
    setSelectedNominationOption(nomination);
    handleOpenApplicationForm();
  }

  return (
    <Layout>
      <Header
        onOpenJuryApplicationForm={handleOpenApplicationJuryForm}
        onOpenApplicationForm={handleOpenApplicationForm}
        allowSendingNominationApplications={
          allow_sending_nomination_applications
        }
        allowSendingJuryApplications={allow_sending_jury_applications}
        locale={locale}
      />
      <Info />
      <Reasons />
      <Nominations
        nominations={nominations}
        nominationApplicationsCounts={nomination_applies_count}
        disableApplications={!allow_sending_nomination_applications}
        disableApplicationsCount={!show_nomination_applies_count}
        onOpenNominationsForm={handleOpenApplicationByNomination}
      />
      <Jury
        awards={awards}
        onOpenJuryApplicationForm={handleOpenApplicationJuryForm}
        disableApplications={!allow_sending_jury_applications}
      />
      <Rules rulesDocument={rulesDocument} />
      <People awards={awards} />
      <Gallery gallery={gallery} />
      <Partners partners={partners} />
      <Feedback />
      <Footer
        facebookUrl={facebook_url}
        phone={phone_number}
        disableApplications={!allow_sending_nomination_applications}
        onOpenApplicationForm={handleOpenApplicationForm}
        locale={locale}
      />
      {openedApplicationJuryForm ? (
        <ApplicationJuryForm
          nominations={nominations}
          currentAward={current_award}
          isOpened={openedApplicationJuryForm}
          onClose={handleCloseApplicationJuryForm}
        />
      ) : null}
      {openedApplicationForm ? (
        <ApplicationForm
          nominations={nominations}
          currentAward={current_award}
          isOpened={openedApplicationForm}
          onClose={handleCloseApplicationForm}
          selectedNominationOption={selectedNominationOption}
          partners={partners}
        />
      ) : null}
    </Layout>
  );
}

export async function getStaticProps({ locale, locales }) {
  const translations = await loadTranslations(locale);

  const data = await getData();

  return {
    props: {
      ...data,
      translations,
      locale,
      locales,
    },
    revalidate: 60 * 10,
  };
}

export default IndexPage;
