import React, { useState, useMemo, useCallback } from "react";
import Icon from "components/Icon";
import classNames from "classnames";
import * as Config from "lib/config";
import { useTranslations } from "lib/translations";

import useGalleryRenderers from "./useGalleryRenderers";
import { SEGMENT_WIDTH } from "./constants";
import styles from "./index.module.scss";
import GalleryViewer from "./GalleryViewer";

const INITIAL_GALLERY_OFFSET = 0;

function Gallery({ gallery: { photos = [], videos = [] } }) {
  const photoesAndVideos = useMemo(() => [...photos, ...videos], [
    photos,
    videos,
  ]);
  const { translate } = useTranslations();

  const [openedGalleryItem, setOpenedGalleryItem] = useState(null);

  const segmentRenderers = useGalleryRenderers({
    photos,
    videos,
    onClick: setOpenedGalleryItem,
  });

  const [galleryOffset, setGalleryOffset] = useState(INITIAL_GALLERY_OFFSET);

  const numberOfSegments = segmentRenderers.length;

  const isLeftArrowActive = galleryOffset < INITIAL_GALLERY_OFFSET;
  const isRightArrowActive =
    galleryOffset >
    INITIAL_GALLERY_OFFSET - (numberOfSegments - 2) * SEGMENT_WIDTH;

  function handleLeftArrowClick() {
    if (isLeftArrowActive) {
      setGalleryOffset((oldOffset) => oldOffset + SEGMENT_WIDTH);
    }
  }

  function handleRightArrowClick() {
    if (isRightArrowActive) {
      setGalleryOffset((oldOffset) => oldOffset - SEGMENT_WIDTH);
    }
  }

  function handleNextGalleryItem() {
    const currentGalleryItemPosition = photoesAndVideos.indexOf(
      openedGalleryItem
    );

    if (currentGalleryItemPosition < photoesAndVideos.length - 1) {
      setOpenedGalleryItem(photoesAndVideos[currentGalleryItemPosition + 1]);
      return;
    }

    setOpenedGalleryItem(photoesAndVideos[0]);
  }

  function handlePreviusGalleryItem() {
    const currentGalleryItemPosition = photoesAndVideos.indexOf(
      openedGalleryItem
    );

    if (currentGalleryItemPosition > 1) {
      setOpenedGalleryItem(photoesAndVideos[currentGalleryItemPosition - 1]);
      return;
    }

    setOpenedGalleryItem(photoesAndVideos[photoesAndVideos.length - 1]);
  }

  return (
    <React.Fragment>
      <section className={styles.container} id="gallery">
        <div className={styles.containerWrap}>
          <div className={styles.top}>
            <h2>{translate("gallery.header")}</h2>
            <div className={styles.topButtons}>
              <Icon
                iconName="arrow"
                className={classNames(styles.left, {
                  [styles.isActive]: isLeftArrowActive,
                })}
                onClick={handleLeftArrowClick}
              />
              <Icon
                iconName="arrow"
                className={classNames(styles.right, {
                  [styles.isActive]: isRightArrowActive,
                })}
                onClick={handleRightArrowClick}
              />
            </div>
          </div>

          <div className={styles.wrapper}>
            <div
              className={styles.gallery}
              style={{
                transform: `translateX(${galleryOffset}px)`,
                transitionTimingFunction: "linier",
              }}
            >
              {segmentRenderers.map((renderer) => renderer())}
            </div>
          </div>
        </div>
      </section>
      {openedGalleryItem ? (
        <GalleryViewer
          item={openedGalleryItem}
          onClose={() => setOpenedGalleryItem(null)}
          onNext={handleNextGalleryItem}
          onPrevius={handlePreviusGalleryItem}
        />
      ) : null}
    </React.Fragment>
  );
}

export default Gallery;
