import React from "react";
import Select from "react-select";
import classNames from "classnames";

import styles from "./styles";

function SelectComponent({
  className,
  options,
  value,
  name,
  onChange,
  placeholder,
  errorClassName,
}) {
  return (
    <Select
      options={options}
      classNamePrefix="react-select"
      className={classNames({ [className]: !!className })}
      styles={styles}
      placeholder={placeholder}
      onChange={onChange}
      isSearchable={false}
      name={name}
      onChange={onChange}
      errorClassName={errorClassName}
      value={value}
    />
  );
}

export default SelectComponent;
