/**
 * This file contains js constants that are equivalent of css varibales for image and video sizes for gallery items
 * 
 * 
 * so if you change vars here, pls change them @ /assets/styles/variables/_variables.scss
 * 
 * $img-width: 264px;
 * $img-height: 165px;

 * $video-width: 528px;
 * $video-height: 330px;

 */

export const IMAGE_WIDTH = 264; // px
export const IMAGE_HEIGHT = 165; // px

export const VIDEO_WIDTH = 2 * IMAGE_WIDTH;
export const VIDEO_HEIGHT = 2 * IMAGE_HEIGHT;

export const SEGMENT_WIDTH = 2 * IMAGE_WIDTH;
export const SEGMENT_HEIGHT = 2 * IMAGE_HEIGHT;
