import React from "react";
import Icon from "components/Icon";

import styles from "./index.module.scss";

function ReasonsItem({ iconName, title, text }) {
  return (
    <div className={styles.container}>
      <Icon iconName={iconName} className={styles.icon} />
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.text}>{text}</p>
    </div>
  );
}

export default ReasonsItem;
