function validate(values) {
  const errors = {};
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!values.full_name) {
    errors.full_name = "errors.required_full_name";
  }

  if (!values.company) {
    errors.company = "errors.required_company"; // "Будь ласка введіть назву компанії";
  }

  if (!values.phone_number) {
    errors.phone_number = "errors.required_phone_number"; // "Будь ласка введіть контактний номер телефону";
  }

  if (!values.email) {
    errors.email = "errors.required_email"; // "Будь ласка введіть контактний номер телефону";
  }

  if (!emailRegex.test(values.email)) {
    errors.email = "errors.valid_email"; //"Будь ласка введіть валідну електронну пошту";
  }

  if (!values.position) {
    errors.position = "errors.required_position"; // "Будь ласка введіть назву компанії";
  }

  if (!values.linkedin) {
    errors.linkedin = "errors.required_linkedin"; // "Будь ласка введіть назву компанії";
  }

  if (!values.describe_projects) {
    errors.describe_projects = "errors.application_requied_field"; // "Будь ласка введіть назву компанії";
  }

  if (!values.best_practices) {
    errors.best_practices = "errors.application_requied_field";
  }

  if (!values.innovative_evaluation) {
    errors.innovative_evaluation = "errors.application_requied_field";
  }

  if (!values.awards_and_certificates) {
    errors.awards_and_certificates = "errors.application_requied_field";
  }

  if (!values.social_responsibility_and_community_development) {
    errors.social_responsibility_and_community_development =
      "errors.application_requied_field";
  }

  if (!values.agreement) {
    errors.agreement = "erros.required_agreement";
  }
  return errors;
}

export default validate;
