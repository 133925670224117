import React, { useMemo } from "react";
import AwardPeopleBlock from "components/AwardPeopleBlock";
import { useTranslations } from "lib/translations";

import styles from "./index.module.scss";

function People({ awards }) {
  const { translate } = useTranslations();

  const awardWinners = useMemo(() => {
    return awards.map((award) => {
      const people = award.nominations.reduce(
        (_people, { nomination, winners }) => {
          return _people.concat(
            ...winners.map((winner) => ({
              ...winner,
              nomination,
            }))
          );
        },
        []
      );

      return [award, people];
    });
  }, [awards]);

  const awardFinalists = useMemo(() => {
    return awards.map((award) => {
      const people = award.nominations.reduce(
        (_people, { nomination, finalists }) => {
          return _people.concat(
            ...finalists.map((finalist) => ({
              ...finalist,
              nomination,
            }))
          );
        },
        []
      );

      return [award, people];
    });
  }, [awards]);

  return (
    <section className={styles.container} id="people">
      <div className={styles.containerWrap}>
        <h2>{translate("participants")}</h2>

        <AwardPeopleBlock
          awardPeople={awardWinners}
          awardPositionLabel={translate("winner_in_nomination")}
          title={translate("winners")}
          avatarSize="lg"
        />

        <AwardPeopleBlock
          awardPeople={awardFinalists}
          awardPositionLabel={translate("finalist_in_nomination")}
          title={translate("finalists")}
          avatarSize="md"
        />
      </div>
    </section>
  );
}

export default People;
