import React, { useEffect, useState, useRef } from "react";
import { useTranslations } from "lib/translations";
import { buildAssetsPath } from "lib/config";
import classNames from "classnames";
import Icon from "components/Icon";
import { useSwipeable } from "react-swipeable";

import styles from "./index.module.scss";

function isPhoto({ mime }) {
  return mime.startsWith("image");
}

function isVideo({ mime }) {
  return mime.startsWith("video");
}

function VideoItem({ src }) {
  const [opaque, setOpaque] = useState();

  const timeoutRef = useRef();

  useEffect(() => {
    setOpaque(false);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setOpaque(true);
    }, 100);
  }, [src]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div
      className={classNames(styles.contentItem, { [styles.opaque]: opaque })}
    >
      <video controls={true} src={buildAssetsPath(src)} />
    </div>
  );
}

function PhotoItem({ src }) {
  const [opaque, setOpaque] = useState();
  const [isLaoded, setIsLoaded] = useState(false);

  const timeoutRef = useRef();

  useEffect(() => {
    setOpaque(false);
    setIsLoaded(false);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setOpaque(true);
    }, 100);
  }, [src]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div
      className={classNames(styles.contentItem, {
        [styles.opaque]: opaque && isLaoded,
      })}
    >
      <img
        src={buildAssetsPath(src)}
        onClick={(e) => e.stopPropagation()}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
}

function GalleryViewer({ item, onNext, onPrevius, onClose }) {
  const { translate } = useTranslations();

  useEffect(() => {
    document.body.style = "overflow: hidden; touch-action: none;";

    return () => {
      document.body.style = "";
    };
  }, []);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: onNext,
    onSwipedRight: onPrevius,
  });

  function handleNext(e) {
    e.preventDefault();
    e.stopPropagation();
    onNext();
  }

  function handlePrevius(e) {
    e.preventDefault();
    e.stopPropagation();
    onPrevius();
  }

  return (
    <div className={styles.overlay} onClick={onClose} {...swipeHandlers}>
      <div className={styles.content}>
        <div className={styles.contentButtons}>
          <button className={styles.contentButtonsClose} onClick={onClose}>
            <Icon iconName="close" className={styles.contentButtonsCloseIcon} />
            <span className={styles.contentButtonsCloseText}>
              {translate("close")}
            </span>
          </button>
        </div>
        <div className={styles.contentArrowLeft} onClick={handlePrevius}>
          <Icon iconName="arrow" className={styles.icon} />
        </div>
        {isPhoto(item) ? <PhotoItem src={item.url} /> : null}
        {isVideo(item) ? <VideoItem src={item.url} /> : null}
        <div className={styles.contentArrowRight} onClick={handleNext}>
          <Icon iconName="arrow" className={styles.icon} />
        </div>
      </div>
    </div>
  );
}

export default GalleryViewer;
