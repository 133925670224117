import React, { useState } from "react";
import YearButton from "components/YearButton";
import Icon from "components/Icon";
import PersonJury from "components/PersonJury";
import PersonDrawer from "components/PersonDrawer";
import * as Config from "lib/config";
import { useTranslations } from "lib/translations";

import styles from "./index.module.scss";

function AwardJuryBlock({
  title,
  awardPositionLabel,
  awardPeople, // tuple with [award, people]
  avatarSize,
  disableApplications,
  onOpenJuryApplicationForm,
}) {
  const { translate, getLocaleField } = useTranslations();
  const [selectedAwardPeople, setSelectedAwardPeople] = useState(
    awardPeople[awardPeople.length - 1]
  );
  const [selectedAward, selectedPersons] = selectedAwardPeople ?? [null, []];
  const [isPersonDrawerOpened, setIsPersonDrawerOpend] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);

  return (
    <React.Fragment>
      <PersonDrawer
        isOpened={isPersonDrawerOpened}
        onClose={() => setIsPersonDrawerOpend(false)}
        person={selectedPerson}
      />
      <section className={styles.container} id="jury">
        <div className={styles.containerWrap}>
          <h2>{title}</h2>

          <div className={styles.years}>
            <div className={styles.yearsWrap}>
              {awardPeople.map((tuple) => {
                const [award] = tuple;
                return (
                  <YearButton
                    key={award.title}
                    className={styles.yearsItem}
                    title={award.title}
                    isActive={award === selectedAward}
                    onClick={() => setSelectedAwardPeople(tuple)}
                  />
                );
              })}
            </div>
            {disableApplications ? null : (
              <button
                className={styles.button}
                onClick={onOpenJuryApplicationForm}
              >
                {translate("become_jury")}
                <Icon iconName="arrow" className={styles.buttonIcon} />
              </button>
            )}
          </div>
          <div className={styles.participants}>
            {selectedPersons.map((person) => (
              <PersonJury
                key={person.id}
                size={avatarSize}
                fullName={getLocaleField(person, "full_name")}
                company={getLocaleField(person, "company")}
                hasNomination={!!person.nomination}
                nominationTitle={person.nomination?.title}
                nominationIcon={person.nomination?.icon?.url}
                awardPosition={awardPositionLabel}
                img={Config.buildAssetsPath(
                  person.photo?.formats?.thumbnail?.url ||
                    person.photo?.formats?.small?.url ||
                    person.photo?.url
                )}
                onClick={() => {
                  setSelectedPerson(person);
                  setIsPersonDrawerOpend(true);
                }}
              />
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default AwardJuryBlock;
