import React from "react";
import Icon from "components/Icon";
import Link from "next/link";
import classNames from "classnames";
import { useTranslations } from "lib/translations";

import styles from "./index.module.scss";

function Footer({
  facebookUrl,
  phone,
  disableApplications,
  onOpenApplicationForm,
  locale,
}) {
  const { translate } = useTranslations();
  return (
    <footer className={styles.container}>
      <div className={styles.containerWrap}>
        <div className={styles.top}>
          <div className={styles.navs}>
            <ul>
              <li>
                <a href="#info" className={styles.navsLink}>
                  {translate("header.about_the_award")}
                </a>
              </li>
              <li>
                <a href="#nominations" className={styles.navsLink}>
                  {translate("header.nominations")}
                </a>
              </li>
              <li>
                <a href="#jury" className={styles.navsLink}>
                  {translate("header.jury")}
                </a>
              </li>
              <li>
                <a href="#rules" className={styles.navsLink}>
                  {translate("header.rules")}
                </a>
              </li>
            </ul>
          </div>

          <div className={styles.navs}>
            <ul>
              <li>
                <a href="#people" className={styles.navsLink}>
                  {translate("header.participants")}
                </a>
              </li>
              <li>
                <a href="#gallery" className={styles.navsLink}>
                  {translate("header.gallery")}
                </a>
              </li>
              <li>
                <a href="#partners" className={styles.navsLink}>
                  {translate("header.partners")}
                </a>
              </li>
              <li>
                <a href="#feedback" className={styles.navsLink}>
                  {translate("header.feedback")}
                </a>
              </li>
            </ul>
          </div>
          <div>
            {disableApplications ? null : (
              <button className={styles.button} onClick={onOpenApplicationForm}>
                {translate("submit_application")}
                <Icon iconName="arrow" className={styles.buttonIcon} />
              </button>
            )}

            <div className={styles.language}>
              <Link href="/" locale="en">
                <a
                  className={classNames(styles.languageEn, {
                    [styles.isActive]: locale === "en",
                  })}
                >
                  En
                </a>
              </Link>
              <Link href="/" locale="ua">
                <a
                  className={classNames(styles.languageUa, {
                    [styles.isActive]: locale === "ua",
                  })}
                >
                  Ua
                </a>
              </Link>
            </div>
          </div>

          <div className={styles.navs}>
            <ul>
              <li className={styles.navsLink}>{phone}</li>
              <li className={styles.navsLink}>
                {translate("we_are_in")}{" "}
                <a href={facebookUrl}>
                  <Icon className={styles.facebook} iconName="facebook" />
                </a>
                Facebook
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <div>
          {new Date().getFullYear()}. {translate("copyright")}
        </div>
        <div className={styles.footerLogo}>
          {translate("site_developed_by")}
          <a
            href="https://senseit.solutions/"
            className={styles.footerLogoLink}
          >
            <Icon iconName="senseit-logo" className={styles.footerLogoIcon} />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
