import React, { useState } from "react";
import Select from "components/Select";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Drawer from "components/Drawer";
import FormField from "components/FormField";
import Icon from "components/Icon";
import { getBackendUrl } from "lib/config";
import * as Config from "lib/config";
import { useTranslations } from "lib/translations";
import validate from "./validation";
import ApplyAccepted from "components/ApplyAcceptedDrawer";
import ErrorApplication from "components/ErrorApplication";
import TextArea from "components/TextArea";

import styles from "./index.module.scss";

function ApplicationJuryForm({ isOpened, onClose, nominations, currentAward }) {
  const { translate } = useTranslations();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  function formatNominationOptions() {
    return nominations.map((item) => ({
      value: item.nomination.id,
      label: (
        <div className={styles.nominationsSelectOption}>
          <img
            src={Config.buildAssetsPath(item.nomination?.icon?.url)}
            className={styles.nominationsSelectIcon}
          />
          <span>{item.nomination.title}</span>
        </div>
      ),
    }));
  }

  const formik = useFormik({
    initialValues: {
      full_name: "",
      position: "",
      company: "",
      phone_number: "",
      email: "",
      linkedin: "",
      skype: "",
      trends: "",
      nominations: [{ id: undefined, key: new Date() }],
      professional_experience: "",
      crucial_personal_traits: "",
      motivation: "",
      agreement: false,
    },
    validate,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: async (values) => {
      try {
        const { agreement, ...data } = {
          ...values,
          nominations: values.nominations.map((item) => item.id),
          award: currentAward?.id,
        };

        const response = await fetch(
          `${getBackendUrl()}/api/jury-applications`,
          {
            method: "post",
            body: JSON.stringify(data),
          }
        );

        if (response.ok) {
          setSuccess(true);
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      }
    },
  });

  function handleSubmit(event, values) {
    event.preventDefault();
    formik.handleSubmit(values);
  }

  return (
    <Drawer
      isOpened={isOpened}
      onClose={onClose}
      className={styles.formWrap}
      closeOnOutsideClick={false}
    >
      <div className={styles.container}>
        <h2>{translate("application_form")}</h2>
        {error ? (
          <ErrorApplication
            onClose={() => {
              setError(false);
              formik.resetForm();
              onClose();
            }}
          />
        ) : (
          <FormikProvider value={formik}>
            {success ? (
              <ApplyAccepted
                applicationType="jury"
                onClose={() => {
                  setSuccess(false);
                  formik.resetForm();
                  onClose();
                }}
                fullName={formik.values.full_name}
              />
            ) : (
              <form
                autocomplete="off"
                className={styles.form}
                onSubmit={handleSubmit}
              >
                <div className={styles.nominations}>
                  <FieldArray name="nominations">
                    {({ remove, push }) => {
                      return (
                        <React.Fragment>
                          {formik.values.nominations.map((_, index) => (
                            <div
                              key={_.key}
                              className={styles.nominationsBlock}
                            >
                              <Select
                                options={formatNominationOptions()}
                                className={styles.nominationsSelect}
                                placeholder={translate("nominations")}
                                onChange={formik.handleChange}
                                name={`nominations.${index}.id`}
                                onChange={(option) => {
                                  formik.setFieldValue(
                                    `nominations.${index}.id`,
                                    option.value
                                  );
                                }}
                                errorClassName={styles.error}
                              />

                              <Icon
                                iconName="close"
                                onClick={() => remove(index)}
                                className={styles.nominationsBlockClose}
                              />
                              <span className={styles.selectError}>
                                {formik.errors.nominations &&
                                  translate(formik.errors.nominations[index])}
                              </span>
                            </div>
                          ))}

                          {formik.values.nominations.length >= 3 ? null : (
                            <p
                              onClick={() => push({ id: "", key: new Date() })}
                              className={styles.nominationsBlockAdd}
                            >
                              + {translate("add_nominations")}
                            </p>
                          )}
                        </React.Fragment>
                      );
                    }}
                  </FieldArray>

                  <span className={styles.nominationsLabel}>
                    {translate("submission_date")}
                  </span>
                </div>

                <FormField
                  className={styles.formField}
                  inputProps={{ className: styles.formInput }}
                  name="full_name"
                  placeholder={translate("application_form_full_name")}
                  onBlur={formik.handleBlur}
                  value={formik.values.full_name}
                  onChange={formik.handleChange}
                  error={translate(formik.errors.full_name)}
                  touched={formik.touched.full_name}
                  errorClassName={styles.error}
                />

                <div className={styles.formGroup}>
                  <FormField
                    name="position"
                    className={styles.formField}
                    inputProps={{ className: styles.formInput }}
                    placeholder={translate("application_form_position")}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.position}
                    error={translate(formik.errors.position)}
                    touched={formik.touched.position}
                    errorClassName={styles.error}
                  />
                  <FormField
                    name="company"
                    className={styles.formField}
                    onChange={formik.handleChange}
                    placeholder={translate("application_form_company")}
                    inputProps={{ className: styles.formInput }}
                    onBlur={formik.handleBlur}
                    value={formik.values.company}
                    error={translate(formik.errors.company)}
                    touched={formik.touched.company}
                    errorClassName={styles.error}
                  />
                </div>

                <div className={styles.formGroup}>
                  <FormField
                    name="email"
                    className={styles.formField}
                    inputProps={{ className: styles.formInput }}
                    placeholder={translate("application_form_mail")}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={translate(formik.errors.email)}
                    touched={formik.touched.email}
                    errorClassName={styles.error}
                  />
                  <FormField
                    name="phone_number"
                    type="tel"
                    className={styles.formField}
                    placeholder={translate("application_form_phone_number")}
                    inputProps={{ className: styles.formInput }}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                    error={translate(formik.errors.phone_number)}
                    touched={formik.touched.phone_number}
                    errorClassName={styles.error}
                  />
                </div>

                <div className={styles.formGroup}>
                  <FormField
                    className={styles.formField}
                    inputProps={{ className: styles.formInput }}
                    name="linkedin"
                    type="url"
                    placeholder={translate("application_form_linkedin")}
                    onBlur={formik.handleBlur}
                    value={formik.values.linkedin}
                    onChange={formik.handleChange}
                    error={translate(formik.errors.linkedin)}
                    touched={formik.touched.linkedin}
                    errorClassName={styles.error}
                  />
                  <FormField
                    className={styles.formField}
                    inputProps={{ className: styles.formInput }}
                    name="skype"
                    placeholder={translate("application_form_skype")}
                    onBlur={formik.handleBlur}
                    value={formik.values.skype}
                    onChange={formik.handleChange}
                    error={translate(formik.errors.skype)}
                    touched={formik.touched.skype}
                    errorClassName={styles.error}
                  />
                </div>
                <TextArea
                  className={styles.formFieldTextArea}
                  textAreaProps={{ className: styles.formTextArea }}
                  name="professional_experience"
                  placeholder={translate("application_form_experience")}
                  onBlur={formik.handleBlur}
                  value={formik.values.professional_experience}
                  onChange={formik.handleChange}
                  error={translate(formik.errors.professional_experience)}
                  touched={formik.touched.professional_experience}
                  errorClassName={styles.error}
                />

                <TextArea
                  className={styles.formFieldTextArea}
                  textAreaProps={{ className: styles.formTextArea }}
                  name="trends"
                  placeholder={translate("application_form_trends")}
                  onBlur={formik.handleBlur}
                  value={formik.values.trends}
                  onChange={formik.handleChange}
                  error={translate(formik.errors.trends)}
                  touched={formik.touched.trends}
                  errorClassName={styles.error}
                />

                <TextArea
                  className={styles.formFieldTextArea}
                  textAreaProps={{ className: styles.formTextArea }}
                  name="crucial_personal_traits"
                  placeholder={translate("application_form_crucial")}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.crucial_personal_traits}
                  error={translate(formik.errors.crucial_personal_traits)}
                  touched={formik.touched.crucial_personal_traits}
                  errorClassName={styles.error}
                />

                <TextArea
                  className={styles.formFieldTextArea}
                  textAreaProps={{ className: styles.formTextArea }}
                  name="motivation"
                  placeholder={translate("application_form_motivation")}
                  onBlur={formik.handleBlur}
                  value={formik.values.motivation}
                  onChange={formik.handleChange}
                  error={translate(formik.errors.motivation)}
                  touched={formik.touched.motivation}
                  errorClassName={styles.error}
                />

                <label className={styles.check}>
                  <FormField
                    type="checkbox"
                    name="agreement"
                    inputProps={{
                      className: styles.formCheck,
                    }}
                    onBlur={formik.handleBlur}
                    onChange={() => {
                      formik.setFieldValue(
                        "agreement",
                        formik.values.agreement ? false : true
                      );
                    }}
                    value={formik.values.agreement}
                  />
                  <p className={styles.checkText}>
                    {translate("application_form_agreement")}
                  </p>
                </label>

                <button
                  className={styles.formSubmit}
                  type="submit"
                  disabled={!formik.values.agreement || formik.isSubmitting}
                >
                  <span>{translate("application_submit")}</span>
                  <Icon iconName="arrow" className={styles.formSubmitIcon} />
                </button>
                {formik.submitCount > 0 && !formik.isValid ? (
                  <p className={styles.validationError}>
                    {translate("errors.submit-message")}
                  </p>
                ) : null}
              </form>
            )}
          </FormikProvider>
        )}
      </div>
    </Drawer>
  );
}

export default ApplicationJuryForm;
