import React from "react";
import FormField from "components/FormField";
import Icon from "components/Icon";
import TextArea from "components/TextArea";
import { useFormik } from "formik";
import FileInput from "components/FileLoader";
import validate from "./validation";
import { useTranslations } from "lib/translations";

import styles from "./index.module.scss";
import Loader from "components/Loader";

function PersonalApplicationForm({ onSubmit }) {
  const { translate } = useTranslations();

  const formik = useFormik({
    initialValues: {
      full_name: "",
      position: "",
      company: "",
      phone_number: "",
      email: "",
      linkedin: "",
      describe_projects: "",
      best_practices: "",
      innovative_evaluation: "",
      awards_and_certificates: "",
      social_responsibility_and_community_development: "",
      attachments: [],
      agreement: false,
    },
    validate,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: async (values) => {
      const { agreement, ...data } = values;
      await onSubmit(data);
      formik.resetForm();
    },
  });

  function handleSubmit(e, values) {
    e.preventDefault();
    formik.handleSubmit(values);
  }

  return (
    <form autocomplete="off" onSubmit={handleSubmit}>
      <FormField
        className={styles.formField}
        inputProps={{ className: styles.formInput }}
        name="full_name"
        placeholder={translate("application_form_full_name")}
        onBlur={formik.handleBlur}
        value={formik.values.full_name}
        onChange={formik.handleChange}
        error={translate(formik.errors.full_name)}
        touched={formik.touched.full_name}
        errorClassName={styles.error}
      />

      <div className={styles.formGroup}>
        <FormField
          name="position"
          className={styles.formField}
          inputProps={{ className: styles.formInput }}
          placeholder={translate("application_form_position")}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.position}
          error={translate(formik.errors.position)}
          touched={formik.touched.position}
          errorClassName={styles.error}
        />
        <FormField
          name="company"
          className={styles.formField}
          onChange={formik.handleChange}
          placeholder={translate("application_form_company")}
          inputProps={{ className: styles.formInput }}
          onBlur={formik.handleBlur}
          value={formik.values.company}
          error={translate(formik.errors.company)}
          touched={formik.touched.company}
          errorClassName={styles.error}
        />
      </div>

      <div className={styles.formGroup}>
        <FormField
          name="email"
          className={styles.formField}
          inputProps={{ className: styles.formInput }}
          placeholder={translate("application_form_mail")}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={translate(formik.errors.email)}
          touched={formik.touched.email}
          errorClassName={styles.error}
        />
        <FormField
          name="phone_number"
          type="tel"
          className={styles.formField}
          placeholder={translate("application_form_phone_number")}
          inputProps={{ className: styles.formInput }}
          onBlur={formik.handleBlur}
          value={formik.values.phone_number}
          onChange={formik.handleChange}
          error={translate(formik.errors.phone_number)}
          touched={formik.touched.phone_number}
          errorClassName={styles.error}
        />
      </div>

      <FormField
        className={styles.formField}
        inputProps={{ className: styles.formInput }}
        name="linkedin"
        placeholder={translate("application_form_linkedin")}
        onBlur={formik.handleBlur}
        value={formik.values.linkedin}
        onChange={formik.handleChange}
        error={translate(formik.errors.linkedin)}
        touched={formik.touched.linkedin}
        errorClassName={styles.error}
      />

      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="describe_projects"
        placeholder={`You are required to describe any project or projects where you have played a key role and reached outstanding achievements. It might be a company project, or the product where you worked/were involved. It is necessary to provide the information on achievements through digital or others statistics indices to reflect the project's/product's performance (for instance, number of users, impact on market, outstanding innovation, development etc.).`}
        onBlur={formik.handleBlur}
        value={formik.values.describe_projects}
        onChange={formik.handleChange}
        error={translate(formik.errors.describe_projects)}
        touched={formik.touched.describe_projects}
        errorClassName={styles.error}
        cols={100}
        rows={1}
      />

      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="best_practices"
        placeholder={`Best practices and evaluation of industrial standards. What best practices were applied towards project/product and your role/effort to implement these best practices? (for instance, practice of software development, delivery practice, product development practice, design scheme, approach to security etc.).`}
        onBlur={formik.handleBlur}
        value={formik.values.best_practices}
        onChange={formik.handleChange}
        error={translate(formik.errors.best_practices)}
        touched={formik.touched.best_practices}
        errorClassName={styles.error}
      />
      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="innovative_evaluation"
        placeholder={
          "Innovative evaluation. What was innovative in your project or product? (for instance, new unique approaches, instruments applied, structures, technologies etc.)."
        }
        onBlur={formik.handleBlur}
        value={formik.values.innovative_evaluation}
        onChange={formik.handleChange}
        error={translate(formik.errors.innovative_evaluation)}
        touched={formik.touched.innovative_evaluation}
        errorClassName={styles.error}
      />
      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="awards_and_certificates"
        placeholder={
          "Awards and certificates. What skills and expertise did you receive during this period of development? (for instance, awards, certification, completed courses, scientific publications etc.)."
        }
        onBlur={formik.handleBlur}
        value={formik.values.awards_and_certificates}
        onChange={formik.handleChange}
        error={translate(formik.errors.awards_and_certificates)}
        touched={formik.touched.awards_and_certificates}
        errorClassName={styles.error}
      />

      <TextArea
        className={styles.formField}
        textAreaProps={{ className: styles.formTextArea }}
        name="social_responsibility_and_community_development"
        placeholder={
          "Social responsibility and community development. Where do you participate as social responsibility entity and entity pursuing professional community development? (for example, projects for animals, community-based replenishment experience and knowledge sharing projects, professional community developing in Ukraine and around the world in role of the speakers at conferences, publishing articles in industry journals and resources, etc.). Please provide a link."
        }
        onBlur={formik.handleBlur}
        value={formik.values.social_responsibility_and_community_development}
        onChange={formik.handleChange}
        error={translate(
          formik.errors.social_responsibility_and_community_development
        )}
        touched={formik.touched.social_responsibility_and_community_development}
        errorClassName={styles.error}
      />
      <FileInput
        name="attachments"
        value={formik.values.attachments}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={translate(formik.errors.attachments)}
        touched={formik.touched.attachments}
        errorClassName={styles.error}
        value={formik.values.attachments}
        onChange={formik.setFieldValue}
        mutliple
        icon={<Icon iconName="clip" className={styles.formInputFileIcon} />}
      />

      <label className={styles.check}>
        <FormField
          type="checkbox"
          name="agreement"
          inputProps={{
            className: styles.formCheck,
          }}
          errorClassName={styles.error}
          onBlur={formik.handleBlur}
          onChange={() =>
            formik.setFieldValue("agreement", !formik.values.agreement)
          }
          value={formik.values.agreement}
        />
        <p className={styles.checkText}>
          {translate("application_form_agreement")}
        </p>
      </label>

      <button
        className={styles.formSubmit}
        type="submit"
        disabled={!formik.values.agreement || formik.isSubmitting}
      >
        {formik.isSubmitting ? (
          <React.Fragment>
            <spam>Sending</spam>
            <Loader />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span>{translate("application_submit")}</span>
            <Icon iconName="arrow" className={styles.formSubmitIcon} />
          </React.Fragment>
        )}
      </button>

      {formik.submitCount > 0 && !formik.isValid ? (
        <p className={styles.validationError}>
          {translate("errors.submit-message")}
        </p>
      ) : null}
    </form>
  );
}

export default PersonalApplicationForm;
