import React, { useMemo } from "react";
import * as Config from "lib/config";
import classnames from "classnames";
import { useTranslations } from "lib/translations";

import styles from "./index.module.scss";

function Partners({ partners }) {
  const { translate, getLocaleField } = useTranslations();
  const { special, casual } = useMemo(() => {
    return {
      special: partners
        .filter(
          (partnerBlock) =>
            partnerBlock.__typename ===
            "ComponentAwardPartnershipsSpecialPartnership"
        )
        .map((itme) => itme.SpecialPartner),
      casual: partners.filter(
        (partnerBlock) =>
          partnerBlock.__typename === "ComponentAwardPartnershipsPartnership"
      ),
    };
  }, [partners]);

  const hasPartners = special.length + casual.length > 0;
  const hasSpecialPartners = special.length > 0;
  const hasCasualPartners = casual.length > 0;

  return hasPartners ? (
    <section className={styles.container} id="partners">
      <div className={styles.containerWrap}>
        <h2>{translate("partners.header")}</h2>

        {hasSpecialPartners
          ? special.map((parnterBlock, index) => {
              return (
                <div key={index} className={styles.wrapper}>
                  {parnterBlock.map((specialPartner) => (
                    <a
                      key={specialPartner.id}
                      href={specialPartner?.partner?.url}
                      target="_blank"
                      className={classnames(
                        styles.content,
                        styles.contentSpecial
                      )}
                    >
                      <p className={styles.label}>
                        {getLocaleField(specialPartner, "title")}
                      </p>
                      <img
                        className={styles.contentImg}
                        src={Config.buildAssetsPath(
                          specialPartner?.partner?.logo?.url
                        )}
                        loading="lazy"
                        alt="partner"
                      />
                    </a>
                  ))}
                </div>
              );
            })
          : null}

        {hasCasualPartners
          ? casual.map((partnership) => (
              <React.Fragment key={partnership.id}>
                <p className={styles.subtitle}>
                  {getLocaleField(partnership, "title")}
                </p>
                <div className={styles.wrapper}>
                  {partnership.partners.map((partner) => (
                    <a
                      key={partner.id}
                      href={partner.url}
                      target="_blank"
                      className={classnames(styles.content, styles.borderRight)}
                    >
                      <img
                        className={styles.contentImg}
                        src={Config.buildAssetsPath(partner.logo?.url)}
                        loading="lazy"
                        alt="partner"
                      />
                    </a>
                  ))}
                </div>
              </React.Fragment>
            ))
          : null}
      </div>
    </section>
  ) : null;
}

export default Partners;
