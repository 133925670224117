import React from "react";
import classnames from "classnames";
import Icon from "components/Icon";
import { useTranslations } from "lib/translations";

import styles from "./index.module.scss";

function Info() {
  const { translate } = useTranslations();
  return (
    <section className={styles.container} id="info">
      <div className={styles.containerWrap}>
        <h2>{translate("info.header")}</h2>

        <div className={styles.content}>
          <div className={styles.contentLeft}>
            <p>{translate("info.p1")} </p>
            <p>{translate("info.p2")}</p>
            <p>{translate("info.p3")}</p>
          </div>
          <div className={styles.contentRight}>
            <p>{translate("info.p4")}</p>
            <p>{translate("info.p5")}</p>
          </div>
        </div>

        <h3>{translate("info.stages_header")}</h3>
        <h3></h3>

        <div className={styles.stages}>
          <div className={styles.stagesItem}>
            <div className={styles.iconBlock}>
              <Icon
                iconName="stage-1-orange "
                className={classnames(styles.animation, styles.animation_first)}
              />
              <Icon
                iconName="stage-1-blue"
                className={classnames(
                  styles.animation,
                  styles.animation_second
                )}
              />
              <Icon
                iconName="stage-1-green"
                className={classnames(styles.animation, styles.animation_third)}
              />

              <Icon
                iconName="stage-1-violet"
                className={classnames(
                  styles.animation,
                  styles.animation_fourth
                )}
              />
            </div>
            {/*<p className={styles.stagesItemDate}>21.09 — 08.11.2020</p>*/}
            <p className={styles.stagesItemText}>{translate("info.stage1")}</p>
          </div>

          <div className={styles.stagesItem}>
            <div className={styles.iconBlock}>
              <Icon
                iconName="stage-2-violet"
                className={classnames(
                  styles.animationSecond,
                  styles.animationSecond_first
                )}
              />
              <Icon
                iconName="stage-2-orange"
                className={classnames(
                  styles.animationSecond,
                  styles.animationSecond_second
                )}
              />
              <Icon
                iconName="stage-2-blue"
                className={classnames(
                  styles.animationSecond,
                  styles.animationSecond_third
                )}
              />

              <Icon
                iconName="stage-2-green"
                className={classnames(
                  styles.animationSecond,
                  styles.animationSecond_fourth
                )}
              />
            </div>
            {/*<p className={styles.stagesItemDate}>08.11 — 22.11.2020</p>*/}
            <p className={styles.stagesItemText}>{translate("info.stage2")}</p>
          </div>

          <div className={styles.stagesItem}>
            <div className={styles.iconBlock}>
              <div
                className={classnames(
                  styles.animationThird,
                  styles.animationThird_first
                )}
              ></div>

              <div
                className={classnames(
                  styles.animationThird,
                  styles.animationThird_second
                )}
              ></div>
              <div
                className={classnames(
                  styles.animationThird,
                  styles.animationThird_third
                )}
              ></div>

              <div
                className={classnames(
                  styles.animationThird,
                  styles.animationThird_fourth
                )}
              ></div>
            </div>
            {/*<p className={styles.stagesItemDate}>10.12.2020</p>*/}
            <p className={styles.stagesItemText}>{translate("info.stage3")}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Info;
