const styles = {
  root: {
    width: "100%",
    margin: "35px 0",
    minHeight: "62px",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #a21ed8",
    position: "relative",
    outline: "none",

    "&:focus": {
      outline: "none",
    },

    outline: {
      "&:focus": {
        outline: "none",
      },
    },
  },

  label: {
    position: "absolute",
    left: "40px",
    bottom: "0",
    top: "0px",
    display: "flex",
    alignItems: "center",
  },

  input: {
    display: "flex",
    opacity: "0",
    outline: "none",
    position: "absolute",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    width: "100%",
    "&:focus": {
      outline: "none",
    },
  },

  iconClose: {
    width: "14px",
    height: "14px",
  },

  files: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    margin: "0 0 0 35px",
  },
  fileName: {
    margin: "10px 0",
  },
};

export default styles;
