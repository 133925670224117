import React from "react";
import { useFormik, FormikProvider, FieldArray } from "formik";
import { useTranslations } from "lib/translations";
import FormField from "components/FormField";
import TextArea from "components/TextArea";
import FileInput from "components/FileLoader";
import Icon from "components/Icon";
import Loader from "components/Loader";

import validate from "./validation";
import styles from "./index.module.scss";

function TeamApplicationForm({ onSubmit }) {
  const { translate } = useTranslations();

  const formik = useFormik({
    initialValues: {
      members: [
        {
          full_name: "",
          position: "",
          email: "",
          phone_number: "",
          linkedin: "",
          areas_of_responsibilities: "",
          key: new Date(),
        },
      ],
      company: "",
      describe_company: "",
      describe_recognition: "",
      describe_impact: "",
      why_should_receive_nomination: "",
      attachments: [],
      agreement: false,
    },
    validate,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: async (values) => {
      const { agreement, ...data } = values;
      await onSubmit(data);
      formik.resetForm();
    },
  });

  function handleSubmit(event, values) {
    event.preventDefault();
    formik.handleSubmit(values);
  }

  return (
    <FormikProvider value={formik}>
      <form autocomplete="off" onSubmit={handleSubmit}>
        <FieldArray name="members">
          {({ remove, push }) => {
            return (
              <div className={styles.membersBlock}>
                 
                {formik.values.members.map((_, index) => (
                  <div key={_.key} className={styles.memberBlock}>
                    <p>
                      Member {index + 1}{" "}
                      <Icon
                        iconName="boldClose"
                        className={styles.iconRemove}
                        onClick={() => remove(index)}
                      />
                    </p>
                    <div className={styles.formGroup}>
                      <FormField
                        className={styles.formField}
                        inputProps={{ className: styles.formInput }}
                        name={`members.${index}.full_name`}
                        placeholder={translate("application_form_full_name")}
                        onBlur={formik.handleBlur}
                        value={formik.values.members[index].full_name}
                        onChange={formik.handleChange}
                        error={translate(
                          formik.errors.members &&
                            formik.errors.members[index]?.full_name
                        )}
                        touched={
                          formik.touched.members &&
                          formik.touched.members[index]?.full_name
                        }
                        errorClassName={styles.error}
                      />
                      <FormField
                        className={styles.formField}
                        inputProps={{ className: styles.formInput }}
                        name={`members.${index}.linkedin`}
                        placeholder={translate("application_form_linkedin")}
                        onBlur={formik.handleBlur}
                        value={formik.values.members[index].linkedin}
                        onChange={formik.handleChange}
                        error={translate(
                          formik.errors.members &&
                            formik.errors?.members[index]?.linkedin
                        )}
                        touched={
                          formik.touched.members &&
                          formik.touched.members[index]?.linkedin
                        }
                        errorClassName={styles.error}
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <FormField
                        name={`members.${index}.position`}
                        className={styles.formField}
                        inputProps={{ className: styles.formInput }}
                        placeholder={translate("application_form_position")}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.members[index].position}
                        error={translate(
                          formik.errors.members &&
                            formik.errors?.members[index]?.position
                        )}
                        touched={
                          formik.touched.members &&
                          formik.touched?.members[index]?.position
                        }
                        errorClassName={styles.error}
                      />
                      <FormField
                        name={`members.${index}.areas_of_responsibilities`}
                        className={styles.formField}
                        onChange={formik.handleChange}
                        inputProps={{ className: styles.formInput }}
                        placeholder={"Team areas of responsibilities"}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.members[index].areas_of_responsibilities
                        }
                        error={translate(
                          formik.errors.members &&
                            formik.errors.members[index]
                              ?.areas_of_responsibilities
                        )}
                        touched={
                          formik.touched.members &&
                          formik.touched.members[index]
                            ?.areas_of_responsibilities
                        }
                        errorClassName={styles.error}
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <FormField
                        name={`members.${index}.email`}
                        className={styles.formField}
                        onChange={formik.handleChange}
                        inputProps={{ className: styles.formInput }}
                        placeholder={translate("application_form_mail")}
                        onBlur={formik.handleBlur}
                        value={formik.values.members[index].email}
                        error={translate(
                          formik.errors.members &&
                            formik.errors?.members[index]?.email
                        )}
                        touched={
                          formik.touched.members &&
                          formik.touched.members[index]?.email
                        }
                        errorClassName={styles.error}
                      />
                      <FormField
                        name={`members.${index}.phone_number`}
                        type="tel"
                        className={styles.formField}
                        inputProps={{ className: styles.formInput }}
                        placeholder={translate("application_form_phone_number")}
                        onBlur={formik.handleBlur}
                        value={formik.values.members[index].phone_number}
                        onChange={formik.handleChange}
                        error={translate(
                          formik.errors.members &&
                            formik.errors?.members[index]?.phone_number
                        )}
                        touched={
                          formik.touched.members &&
                          formik.touched.members[index]?.phone_number
                        }
                        errorClassName={styles.error}
                      />
                    </div>
                  </div>
                ))}
                {formik.values.members.length >= 3 ? null : (
                  <p
                    onClick={() =>
                      push({
                        full_name: "",
                        position: "",
                        email: "",
                        phone_number: "",
                        linkedin: "",
                        responsibilities: "",
                        key: new Date(),
                      })
                    }
                    className={styles.memberBlockAdd}
                  >
                    +  {translate("add_member")}
                  </p>
                )}
              </div>
            );
          }}
        </FieldArray>
        <FormField
          name="company"
          className={styles.formField}
          onChange={formik.handleChange}
          placeholder={translate("application_form_company")}
          inputProps={{ className: styles.formInput }}
          onBlur={formik.handleBlur}
          value={formik.values.company}
          error={translate(formik.errors.company)}
          touched={formik.touched.company}
          errorClassName={styles.error}
        />
        <TextArea
          className={styles.formField}
          textAreaProps={{ className: styles.formTextArea }}
          name="describe_company"
          placeholder={
            "Describe a company, organization or product which made an impact on business. environment, community or society."
          }
          onBlur={formik.handleBlur}
          value={formik.values.describe_company}
          onChange={formik.handleChange}
          error={translate(formik.errors.describe_company)}
          touched={formik.touched.describe_company}
          errorClassName={styles.error}
        />
        <TextArea
          className={styles.formField}
          textAreaProps={{ className: styles.formTextArea }}
          name="describe_recognition"
          placeholder={"Describe recognition which you already have."}
          onBlur={formik.handleBlur}
          value={formik.values.describe_recognition}
          onChange={formik.handleChange}
          error={translate(formik.errors.describe_recognition)}
          touched={formik.touched.describe_recognition}
          errorClassName={styles.error}
        />
        <TextArea
          className={styles.formField}
          textAreaProps={{ className: styles.formTextArea }}
          name="describe_impact"
          placeholder={"Describe in detail the impact which your team made."}
          onBlur={formik.handleBlur}
          value={formik.values.describe_impact}
          onChange={formik.handleChange}
          error={translate(formik.errors.describe_impact)}
          touched={formik.touched.describe_impact}
          errorClassName={styles.error}
        />
        <TextArea
          className={styles.formField}
          textAreaProps={{ className: styles.formTextArea }}
          name="why_should_receive_nomination"
          placeholder={"Why should you receive that nomination?"}
          onBlur={formik.handleBlur}
          value={formik.values.why_should_receive_nomination}
          onChange={formik.handleChange}
          error={translate(formik.errors.why_should_receive_nomination)}
          touched={formik.touched.why_should_receive_nomination}
          errorClassName={styles.error}
        />
        <FileInput
          name="attachments"
          value={formik.values.attachments}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={translate(formik.errors.attachments)}
          touched={formik.touched.attachments}
          errorClassName={styles.error}
          value={formik.values.attachments}
          onChange={formik.setFieldValue}
          mutliple
          placeholder={translate("attach_files")}
          icon={<Icon iconName="clip" className={styles.formInputFileIcon} />}
        />

        <label className={styles.check}>
          <FormField
            type="checkbox"
            name="agreement"
            inputProps={{
              className: styles.formCheck,
            }}
            errorClassName={styles.error}
            onBlur={formik.handleBlur}
            onChange={() =>
              formik.setFieldValue("agreement", !formik.values.agreement)
            }
            value={formik.values.agreement}
          />
          <p className={styles.checkText}>
            {translate("application_form_agreement")}
          </p>
        </label>

        <button
          className={styles.formSubmit}
          type="submit"
          disabled={!formik.values.agreement || formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <React.Fragment>
              <spam>Sending</spam>
              <Loader />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <span>{translate("application_submit")}</span>
              <Icon iconName="arrow" className={styles.formSubmitIcon} />
            </React.Fragment>
          )}
        </button>

        {formik.submitCount > 0 && !formik.isValid ? (
          <p className={styles.validationError}>
            {translate("errors.submit-message")}
          </p>
        ) : null}
      </form>
    </FormikProvider>
  );
}

export default TeamApplicationForm;
