import React, { useState, useCallback } from "react";
import Icon from "components/Icon";
import ClickOutsideListener from "components/ClickOutsideListener";
import classnames from "classnames";
import * as Config from "lib/config";
import { useTranslations } from "lib/translations";
import MarkDown from "react-markdown";

import styles from "./index.module.scss";

function NominationItem({
  count,
  nomination,
  juries,
  disableApplications,
  disableApplicationsCount,
  onOpenNominationsForm,
}) {
  const { translate } = useTranslations();
  const { getLocaleField } = useTranslations();

  const Code = React.useMemo(() => {
    return function ({ children }) {
      if (children === "{juries}") {
        return juries.length ? (
          <React.Fragment>
            <p>{translate("jury")}</p>
            <div style={{ lineHeight: 0 }}>
              {juries.map((jury) => {
                return (
                  <img
                    key={jury.id}
                    src={Config.buildAssetsPath(
                      jury.photo?.formats?.thumbnail?.url ||
                        jury.photo?.formats?.small?.url ||
                        jury.photo?.url
                    )}
                    alt="person-photo"
                    loading="lazy"
                  />
                );
              })}
            </div>
          </React.Fragment>
        ) : null;
      }

      return null;
    };
  }, [juries]);

  function handleOpenNominationsForm(e) {
    e.stopPropagation();
    e.preventDefault();
    onOpenNominationsForm(nomination.id);
  }

  const [isHovered, setIsHovered] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const description = getLocaleField(nomination, "description");

  function handleMouseIn() {
    setIsHovered(true);
  }

  function handleMouseOut() {
    setIsHovered(false);
  }

  function handleOpen() {
    return setIsOpened(true);
  }

  const handleClose = useCallback(() => {
    setIsOpened(false);
  }, []);

  return (
    <ClickOutsideListener onClickOutside={handleClose}>
      <div
        className={classnames(styles.details, {
          [styles.isOpened]: isOpened,
        })}
      >
        <div
          onMouseEnter={handleMouseIn}
          onMouseLeave={handleMouseOut}
          className={styles.container}
          onClick={handleOpen}
          style={{
            backgroundColor: isHovered ? nomination?.color : undefined,
          }}
        >
          <img
            src={Config.buildAssetsPath(nomination?.icon?.url)}
            className={styles.containerImg}
          />

          <h4 className={styles.containerTitle}>{nomination?.title}</h4>

          <span className={styles.containerCount}>
            {disableApplicationsCount ? null : count}
          </span>

          {disableApplications ? null : (
            <button
              className={styles.containerBtn}
              onClick={handleOpenNominationsForm}
            >
              {translate("apply")}
              <Icon iconName="arrow" className={styles.containerBtnIcon} />
            </button>
          )}

          <Icon iconName="arrow" className={styles.containerIcon} />
        </div>

        <article
          className={styles.detailsContent}
          style={{
            backgroundColor: nomination?.color,
          }}
        >
          <div className={styles.detailsTitle}>
            <img
              src={Config.buildAssetsPath(nomination?.icon?.url)}
              className={styles.detailsImg}
            />
            <h4 className={styles.name}>{nomination?.title}</h4>
            <div className={styles.count}>
              {disableApplicationsCount ? null : count}
            </div>
            <div className={styles.detailsButtonsTop}>
              {disableApplications ? null : (
                <button
                  className={styles.detailsButtonsApply}
                  onClick={handleOpenNominationsForm}
                >
                  {translate("apply")}
                  <Icon
                    iconName="arrow"
                    className={styles.detailsButtonsApplyIcon}
                  />
                </button>
              )}
              <button
                className={styles.detailsButtonsClose}
                onClick={handleClose}
              >
                <Icon
                  iconName="close"
                  className={styles.detailsButtonsApplyIcon}
                />
              </button>
            </div>
          </div>
          {description ? (
            <div className={styles.detailsDescription}>
              <MarkDown
                source={description}
                renderers={{
                  inlineCode: Code,
                }}
              />
            </div>
          ) : null}
          <div className={styles.detailsButtons}>
            {disableApplications ? null : (
              <button
                onClick={handleOpenNominationsForm}
                className={styles.detailsButtonsApply}
              >
                {translate("apply")}
                <Icon
                  iconName="arrow"
                  className={styles.detailsButtonsApplyIcon}
                />
              </button>
            )}
            <button
              className={styles.detailsButtonsClose}
              onClick={handleClose}
            >
              <Icon
                iconName="close"
                className={styles.detailsButtonsCloseIcon}
              />
              <span className={styles.detailsButtonsCloseText}>
                {translate("close")}
              </span>
            </button>
          </div>
        </article>
      </div>
    </ClickOutsideListener>
  );
}

export default NominationItem;
