import React from "react";
import classNames from "classnames";

import styles from "./index.module.scss";

function TextArea({
  type,
  id,
  name,
  value,
  autoComplete,
  // optional props
  icon,
  placeholder,
  disabled,
  onBlur,
  onChange,
  className,
  error,
  touched,
  cols,
  rows,
  textAreaProps,
  errorClassName,
}) {
  const [focused, setFocused] = React.useState(false);
  function handleFocus() {
    setFocused(true);
  }

  function handleBlur(e) {
    setFocused(false);
    onBlur(e);
  }

  return (
    <div
      className={classNames(styles.container, {
        [className]: !!className,
        [styles.isFocused]: focused || value,
        [styles.isError]: error && touched,
      })}
    >
      {icon ? icon : null}
      <label
        className={classNames(styles.label, {
          [styles.isError]: error && touched,
          [styles.isFocused]: focused || value,
        })}
      >
        {placeholder || label}
      </label>
      <textarea
        id={id}
        onFocus={handleFocus}
        cols={cols}
        rows={5}
        type={type}
        name={name}
        onBlur={handleBlur}
        value={value}
        autoComplete={autoComplete}
        disabled={disabled}
        onChange={onChange}
        {...textAreaProps}
        className={classNames(styles.textarea, {
          [textAreaProps.className]: !!textAreaProps.className,
          [styles.isError]: error && touched,
        })}
      />
      {error && touched ? (
        <span
          className={classNames(styles.error, {
            [errorClassName]: !!errorClassName,
          })}
        >
          {error}
        </span>
      ) : null}
    </div>
  );
}

export default TextArea;
